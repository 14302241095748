import React from 'react';

export const Ordered = ({ size = 16, color = 'currentColor' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill={color}
			viewBox="0 0 16 16"
		>
			<circle cx="1.2" cy="5.2" r="1.2" />
			<circle cx="4.6" cy="5.2" r="1.2" />
			<circle cx="8" cy="5.2" r="1.2" />
			<circle cx="11.4" cy="5.2" r="1.2" />
			<circle cx="14.8" cy="5.2" r="1.2" />
			<circle cx="1.2" cy="10.8" r="1.2" />
			<circle cx="4.6" cy="10.8" r="1.2" />
			<circle cx="8" cy="10.8" r="1.2" />
		</svg>
	);
};
