import React, { useState, useContext } from 'react';
import { Flex, VStack, Divider } from '@chakra-ui/react';
import {
	Row,
	HeadingCol,
	IconButtonCol,
	SelectCol,
	AddShapeCol,
	ImageReaderCol,
} from '../Features';
import { TextIcon } from '../../icons/Shapes';
import { addText, addCutGrid } from '../../fabricCanvas';
import CutoutContext from './CutoutContext';

const letterOptions =
	'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

const numberOptions = '0123456789'.split('');

const gridOptions = [
	'None',
	'1 x 1',
	'2 x 1',
	'2 x 2',
	'3 x 1',
	'3 x 2',
	'3 x 3',
];

const CutoutAdd = () => {
	const [grid, setGrid] = useState(gridOptions[0]);
	const { canvas, fill, stroke } = useContext(CutoutContext);

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<HeadingCol text="Basic" />
					<AddShapeCol canvas={canvas} />
					<ImageReaderCol canvas={canvas} />
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Cutting Grid" />
					<SelectCol
						options={gridOptions}
						value={grid}
						onChange={(e) => {
							const newGrid = e.target.value;
							setGrid(newGrid);
							canvas.getObjects().forEach((obj) => {
								if (obj.as === 'cut_grid') {
									canvas.remove(obj);
									canvas.renderAll();
								}
							});
							if (newGrid !== gridOptions[0]) {
								addCutGrid(
									canvas,
									newGrid.split` x `.map((num) =>
										parseInt(num)
									)
								);
							}
						}}
					/>
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Letters" />
					{letterOptions.map((option, key) => (
						<IconButtonCol
							key={key}
							label={option}
							icon={<TextIcon text={option} />}
							onClick={() => {
								const activeObject =
									canvas.getActiveObjects()[0];
								if (
									activeObject &&
									activeObject.isType('text')
								) {
									activeObject.set({ text: option });
									canvas.renderAll();
								} else {
									addText(canvas, {
										text: option,
										fontFamily: 'Fredoka One',
										fontSize: 300,
										fill: fill,
										stroke: stroke,
									});
								}
							}}
						/>
					))}
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Numbers" />
					{numberOptions.map((option, key) => (
						<IconButtonCol
							key={key}
							label={option}
							icon={<TextIcon text={option} />}
							onClick={() => {
								const activeObject =
									canvas.getActiveObjects()[0];
								if (
									activeObject &&
									activeObject.isType('text')
								) {
									activeObject.set({ text: option });
									canvas.renderAll();
								} else {
									addText(canvas, {
										text: option,
										fontFamily: 'Fredoka One',
										fontSize: 300,
										fill: fill,
										stroke: stroke,
									});
								}
							}}
						/>
					))}
				</Row>
			</VStack>
		</Flex>
	);
};

export default CutoutAdd;
