import { addIText, addRect } from '../../fabricCanvas';

export const example_1 = (canvas) => {
	addRect(canvas, {
		left: 10,
		top: 10,
		width: 589,
		height: 769,
	});
	addIText(canvas, {
		fontFamily: 'Times New Roman',
		fontSize: 80,
		text: 'My Name is ...',
		left: 67.74609375,
		top: 70,
	});
	addIText(canvas, {
		fontFamily: 'KG Primary Dots',
		fontSize: 220,
		text: 'Avery',
		left: 70.4609375,
		top: 125,
	});
	canvas.getActiveObjects()[0].clone(
		(cloned) => {
			canvas.discardActiveObject();
			cloned.top += 175;
			canvas.add(cloned);
			canvas.setActiveObject(cloned);
		},
		['uid', 'centeredScaling', '_controlsVisibility']
	);
	canvas.getActiveObjects()[0].clone(
		(cloned) => {
			canvas.discardActiveObject();
			cloned.top += 175;
			canvas.add(cloned);
			canvas.setActiveObject(cloned);
		},
		['uid', 'centeredScaling', '_controlsVisibility']
	);
	addIText(canvas, {
		fontFamily: 'Times New Roman',
		fontSize: 16,
		text: '@earlyeducationlab.com',
		left: 435,
		top: 757,
	});
	canvas.discardActiveObject();
};
