import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
	Flex,
	Center,
	AspectRatio,
	Heading,
	Icon,
	Text,
} from '@chakra-ui/react';
import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import { Count123, TraceE } from '../icons';
import { BsHouse, BsExclamationCircle } from 'react-icons/bs';
import { RiScissorsCutLine } from 'react-icons/ri';

const tabList = [
	{ path: '/', name: 'Home', TabIcon: BsHouse },
	{
		path: '/trace',
		name: 'Trace',
		TabIcon: TraceE,
	},
	{
		path: '/count',
		name: 'Count',
		TabIcon: Count123,
	},
	{
		path: '/cutout',
		name: 'Cutout',
		TabIcon: RiScissorsCutLine,
	},
	{
		path: '/about',
		name: 'About',
		TabIcon: BsExclamationCircle,
	},
];

const LeftNav = () => {
	//initialize
	const { pathname } = useLocation();
	const history = useHistory();
	const [isExpand] = useMediaQuery('(min-width: 1048px)');

	const handleTabsChange = (index) => {
		history.push(tabList[index].path);
	};

	//return
	return (
		<Flex
			bg="macaron.blue"
			direction="column"
			w={isExpand ? '120px' : '50px'}
			shrink="0"
		>
			<AspectRatio
				ratio={1}
				borderBottom="1px solid"
				borderBottomColor="macaron.blue_dark"
				mb={8}
			>
				<Center>
					<Heading as="h1" size="md" color="white">
						{isExpand ? (
							<>
								Early
								<br />
								Education
								<br />
								Lab
							</>
						) : (
							<>EEL</>
						)}
					</Heading>
				</Center>
			</AspectRatio>
			<Tabs
				variant="unstyled"
				orientation="vertical"
				color="white"
				index={tabList.map((tab) => tab.path).indexOf(pathname)}
				onChange={handleTabsChange}
			>
				<TabList w="100%">
					{tabList.map(({ TabIcon, name }, key) => (
						<Tab
							_selected={{ bg: 'macaron.yellow' }}
							h="50px"
							justifyContent={isExpand ? 'start' : 'center'}
							key={key}
						>
							<Icon as={TabIcon} w={4} h={4} />
							{isExpand ? <Text ml={2}>{name}</Text> : null}
						</Tab>
					))}
				</TabList>
			</Tabs>
		</Flex>
	);
};

export default LeftNav;
