import React from 'react';
import { useHistory } from 'react-router-dom';

import {
	Flex,
	Box,
	SimpleGrid,
	Center,
	VStack,
	Image,
	Heading,
	Text,
	UnorderedList,
	ListItem,
	Link,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import homepage from '../images/homepage.png';
import trace_example_1 from '../images/trace_example_1.png';
import trace_example_2 from '../images/trace_example_2.png';
import count_example_1 from '../images/count_example_1.png';
import count_example_2 from '../images/count_example_2.png';
import cutout_example_1 from '../images/cutout_example_1.png';
import cutout_example_2 from '../images/cutout_example_2.png';

const Home = () => {
	let history = useHistory();
	const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

	return (
		<Flex
			bg="macaron.white"
			flex="1"
			direction="column"
			justify="space-between"
			overflow="auto"
			p="32px 4%"
		>
			<Box mb={8}>
				<SimpleGrid columns={isLargerThan600 ? 2 : 1} mb={8}>
					<Center flexDirection="column" p={4}>
						<Heading
							as="h1"
							size="2xl"
							alignSelf="start"
							lineHeight="1.5"
						>
							Simple way to create custom materials for Pre-K
							teaching
						</Heading>
						<Heading as="h1" size="2xl" alignSelf="end" mt={6}>
							- it's
							<Text as="span" color="macaron.red">
								{' '}
								Free
							</Text>
						</Heading>
					</Center>
					<Center>
						<Image
							src={homepage}
							alt="homepage"
							w="100%"
							maxW="600px"
						/>
					</Center>
				</SimpleGrid>
				<Box minH="400px" p={4}>
					<VStack spacing={8}>
						<Flex
							w="100%"
							bg="white"
							direction="column"
							borderRadius="md"
							p="calc(12px + 2%)"
						>
							<Heading
								as="h1"
								size="md"
								mb={6}
								color="macaron.blue"
							>
								Trace
							</Heading>
							<Text fontSize="md" mb={6}>
								Trace worksheet creating tool allows user to
								create and edit a traceable worksheet for
								student to learn writing. We reduce the
								complexity of creating such files while we
								provide you the ability to customize your own
								worksheet for students or kids.
							</Text>
							<UnorderedList fontSize="md" mb={6}>
								<ListItem>Add traceable font</ListItem>
								<ListItem>Add traceable basic shapes</ListItem>
								<ListItem>Flexible font scaling </ListItem>
								<ListItem>Deep duplicate</ListItem>
								<ListItem>PNG and PDF download</ListItem>
								<ListItem>No watermark and footer</ListItem>
							</UnorderedList>
							<Text fontSize="md" mb={6}>
								Deep duplicate - all copies connect to each
								other. If one of them changes font or color, all
								other copies will reflect on the changes.
							</Text>
							<SimpleGrid
								columns={isLargerThan600 ? 2 : 1}
								spacing={4}
								mb={6}
							>
								<Center>
									<Image
										src={trace_example_1}
										alt="trace_example_1"
										border="1px solid black"
										w="100%"
									/>
								</Center>
								<Center>
									<Image
										src={trace_example_2}
										alt="trace_example_2"
										border="1px solid black"
										w="100%"
									/>
								</Center>
							</SimpleGrid>
							<Link
								color="macaron.blue_dark"
								alignSelf="flex-end"
								onClick={() => history.push('/trace')}
							>
								Go to Trace
							</Link>
						</Flex>
						<Flex
							w="100%"
							bg="white"
							direction="column"
							borderRadius="md"
							p="calc(12px + 2%)"
						>
							<Heading
								as="h1"
								size="md"
								mb={6}
								color="macaron.blue"
							>
								Count
							</Heading>
							<Text fontSize="md" mb={6}>
								Count worksheet creating tool allows user to
								create and edit a counting worksheet for student
								to practice counting. We provide you the feature
								to generate a random number of objects so you
								could customize it with your own idea on how a
								counting worksheet presents.
							</Text>
							<UnorderedList fontSize="md" mb={6}>
								<ListItem>
									Add ordered random number of objects
								</ListItem>
								<ListItem>
									Add unordered random number of objects
								</ListItem>
								<ListItem>
									Add texts, numbers, and basic shapes
								</ListItem>
								<ListItem>Flexible scaling </ListItem>
								<ListItem>PNG and PDF download</ListItem>
								<ListItem>No watermark and footer</ListItem>
							</UnorderedList>
							<SimpleGrid
								columns={isLargerThan600 ? 2 : 1}
								spacing={4}
								mb={6}
							>
								<Center>
									<Image
										src={count_example_1}
										alt="count_example_1"
										border="1px solid black"
										w="100%"
									/>
								</Center>
								<Center>
									<Image
										src={count_example_2}
										alt="count_example_2"
										border="1px solid black"
										w="100%"
									/>
								</Center>
							</SimpleGrid>
							<Link
								color="macaron.blue_dark"
								alignSelf="flex-end"
								onClick={() => history.push('/count')}
							>
								Go to Count
							</Link>
						</Flex>
						<Flex
							w="100%"
							bg="white"
							direction="column"
							borderRadius="md"
							p="calc(12px + 2%)"
						>
							<Heading
								as="h1"
								size="md"
								mb={6}
								color="macaron.blue"
							>
								Cutout
							</Heading>
							<Text fontSize="md" mb={6}>
								Cutout tool allows user to print letter and
								number character for cutting in the easiest way.
								You only need to add the characters, resize
								them, and color them. Print it and cut them out!
							</Text>
							<UnorderedList fontSize="md" mb={6}>
								<ListItem>Quick adding characters</ListItem>
								<ListItem>
									Quick replacing unwanted characters
								</ListItem>
								<ListItem>
									Sync font and color to all existing
									characters
								</ListItem>
								<ListItem>Flexible scaling </ListItem>
								<ListItem>PNG and PDF download</ListItem>
								<ListItem>No watermark and footer</ListItem>
							</UnorderedList>
							<SimpleGrid
								columns={isLargerThan600 ? 2 : 1}
								spacing={4}
								mb={6}
							>
								<Center>
									<Image
										src={cutout_example_1}
										alt="cutout_example_1"
										border="1px solid black"
										w="100%"
									/>
								</Center>
								<Center>
									<Image
										src={cutout_example_2}
										alt="cutout_example_2"
										border="1px solid black"
										w="100%"
									/>
								</Center>
							</SimpleGrid>
							<Link
								color="macaron.blue_dark"
								alignSelf="flex-end"
								onClick={() => history.push('/cutout')}
							>
								Go to Cutout
							</Link>
						</Flex>
					</VStack>
				</Box>
			</Box>
			<Box>
				<UnorderedList
					textAlign="center"
					listStyleType="none"
					color="macaron.blue_dark"
					m={0}
				>
					<ListItem display="inline" mr={8}>
						<Link href="/privacy">Privacy Policy</Link>
					</ListItem>
					<ListItem display="inline">
						<Link href="/terms">Terms and Conditions</Link>
					</ListItem>
				</UnorderedList>
			</Box>
		</Flex>
	);
};

export default Home;
