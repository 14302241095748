import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const styles = {
	global: {
		html: {
			height: '100%',
		},
		body: {
			margin: '0',
			padding: '0',
			position: 'fixed',
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			userSelect: 'none',
			webkitOverflowScrolling: 'touch',
		},
		'#root': {
			height: '100%',
			display: 'flex',
			overflow: 'hidden',
		},
		'::-webkit-scrollbar': {
			width: '4px',
			height: '4px',
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: 'gray.300',
			borderRadius: '2px',
		},
	},
};

export const colors = {
	macaron: {
		yellow: '#f3d250',
		yellow_dark: '#f0c620',
		yellow_light: '#f6de80',
		blue: '#90ccf4',
		blue_dark: '#62b7ef',
		blue_light: '#bee1f9',
		red: '#f78888',
		red_dark: '#f45858',
		red_light: '#fab8b8',
		white: '#ececec',
		cyan: '#66c2c8',
		cyan_dark: '#42b1b9',
		cyan_light: '#8cd1d5',
	},
	macaron_blue: {
		50: '#fbfdff',
		100: '#e9f5fd',
		200: '#d7edfb',
		300: '#c6e5f9',
		400: '#b4dcf8',
		500: '#a2d4f6',
		600: '#90ccf4',
		700: '#7ec4f2',
		800: '#6cbcf0',
		900: '#5ab3ef',
	},
	macaron_cyan: {
		50: '#C1E6E9',
		100: '#B2E0E3',
		200: '#A3DADE',
		300: '#93D4D8',
		400: '#84CED3',
		500: '#75C8CD',
		600: '#66c2c8',
		700: '#5BAEB4',
		800: '#519BA0',
		900: '#47878C',
	},
};

const components = {
	Button: {
		baseStyle: {
			_focus: {
				boxShadow: 'none',
			},
		},
	},
	Tabs: {
		parts: ['tabs', 'tablist', 'tab'],
		baseStyle: {
			tab: {
				_focus: {
					boxShadow: 'none',
				},
			},
		},
	},
	Popover: {
		parts: ['popper', 'content'],
		baseStyle: {
			content: {
				_focus: {
					boxShadow: 'none',
				},
			},
		},
	},
	Switch: {
		parts: ['track'],
		baseStyle: {
			track: {
				_focus: {
					boxShadow: 'none',
				},
			},
		},
	},
	Radio: {
		parts: ['control'],
		baseStyle: {
			control: {
				_focus: {
					boxShadow: 'none',
				},
			},
		},
	},
	Divider: {
		baseStyle: {
			opacity: 1,
			borderColor: 'gray.300',
		},
	},
};

const theme = extendTheme({ styles, colors, components });

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<App />
	</ChakraProvider>,
	document.getElementById('root')
);
