import React from 'react';
import { Box } from '@chakra-ui/react';
import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react';

export const FontSizeCol = ({ canvas, fontSize, setFontSize }) => {
	const updateFontSize = (newFontSize) => {
		setFontSize(newFontSize);
		const activeObject = canvas.getActiveObjects()[0];
		if (activeObject && 'fontSize' in activeObject) {
			canvas.getObjects().forEach((obj) => {
				if (obj.uid === activeObject.uid) {
					obj.set({ fontSize: newFontSize });
				}
			});
			canvas.renderAll();
		}
	};

	const onInputChange = (e) => updateFontSize(e.target.value);

	const onMinusClick = () => {
		updateFontSize(fontSize - 1);
	};

	const onPlusClick = () => {
		updateFontSize(fontSize + 1);
	};

	return (
		<Box flex="0 0 120px" p={2}>
			<NumberInput defaultValue={24} min={1} value={fontSize}>
				<NumberInputField bg="white" onChange={onInputChange} />
				<NumberInputStepper>
					<NumberIncrementStepper onClick={onPlusClick} />
					<NumberDecrementStepper onClick={onMinusClick} />
				</NumberInputStepper>
			</NumberInput>
		</Box>
	);
};
