import React from 'react';
import {
	Box,
	Button,
	IconButton,
	Heading,
	AspectRatio,
	Select,
} from '@chakra-ui/react';

export const HeadingCol = ({ width = '100%', text = 'Heading', ...props }) => (
	<Box flex={`0 0 ${width}`} p={2} {...props}>
		<Heading as="h1" size="sm">
			{text}
		</Heading>
	</Box>
);

export const ButtonCol = ({
	width = '240px',
	innerText = 'Button',
	fontFamily = 'inherit',
	onClick = null,
}) => (
	<Box flex={`0 0 ${width}`} p={2}>
		<Button w="100%" bg="white" fontFamily={fontFamily} onClick={onClick}>
			{innerText}
		</Button>
	</Box>
);

export const IconButtonCol = ({
	width = '60px',
	label = 'IconButton',
	icon = null,
	onClick = null,
}) => (
	<Box flex={`0 0 ${width}`} p={2}>
		<AspectRatio w="100%" ratio={1}>
			<IconButton
				w="100%"
				h="100%"
				bg="white"
				aria-label={label}
				icon={icon}
				onClick={onClick}
			/>
		</AspectRatio>
	</Box>
);

export const SelectCol = ({
	width = '240px',
	options = [],
	value = null,
	onChange = null,
}) => (
	<Box flex={`0 0 ${width}`} p={2}>
		<Select bg="white" value={value} onChange={onChange}>
			{options.map((option, key) => (
				<option key={key}>{option}</option>
			))}
		</Select>
	</Box>
);
