import React from 'react';
import { addIText, addRect } from '../../fabricCanvas';
import { ButtonCol } from './index';

export const PresetCols = {
	Name: ({ canvas }) => {
		return (
			<ButtonCol
				innerText="Name:________"
				onClick={() =>
					addIText(canvas, {
						fontFamily: 'Times New Roman',
						fontSize: 24,
						text: 'Name:________',
						left: 80,
						top: 20,
					})
				}
			/>
		);
	},
	Class: ({ canvas }) => {
		return (
			<ButtonCol
				innerText="Class:________"
				onClick={() =>
					addIText(canvas, {
						fontFamily: 'Times New Roman',
						fontSize: 24,
						text: 'Class:________',
						left: 380,
						top: 20,
					})
				}
			/>
		);
	},
	PageBorder: ({ canvas }) => {
		return (
			<ButtonCol
				innerText="Page Border"
				onClick={() =>
					addRect(canvas, {
						width: 590,
						height: 770,
						left: 9.5,
						top: 9.5,
					})
				}
			/>
		);
	},
};
