import React from 'react';
import {
	Flex,
	Box,
	Container,
	Heading,
	Text,
	UnorderedList,
	ListItem,
	Link,
} from '@chakra-ui/react';

const About = () => {
	return (
		<Flex
			bg="macaron.white"
			flex="1"
			direction="column"
			justify="space-between"
			overflow="auto"
			p={8}
		>
			<Box mb={8}>
				<Container maxW="container.lg" mb={8}>
					<Heading
						as="h1"
						color="macaron.red"
						textAlign="center"
						mb={6}
					>
						About us
					</Heading>
					<Text fontSize="xl" mb={6}>
						Early Education Lab is online tools for preschool
						teachers and parents to create custom learning materials
						for Pre-K students. These tools allow you to creat
						traceable letters, numbers and shapes for kids to learn
						writing, to create object counting worksheet for kid to
						recognize number and practicing counting, and to create
						letters' and numbers' cutout template for decorating
						classroom or party.
					</Text>
					<Text fontSize="xl" mb={6}>
						Early Education Lab is developed and supported by a web
						developer and a preschool teacher in New York. We are
						still looking for ways to improve early education
						teaching. We will continue to improve existing tools and
						develop more tools in the futures.
					</Text>
					<Text fontSize="xl">
						More features are still under development, such as to
						create simple math worksheet, to create school
						newsletter, to create lesson plan, etc. We will post our
						new features once they were ready.
					</Text>
				</Container>
				<Container maxW="container.lg">
					<Heading
						as="h1"
						color="macaron.red"
						textAlign="center"
						mb={6}
					>
						Contact us
					</Heading>
					<Text fontSize="xl" mb={6}>
						Email: earlyeducationlab@gmail.com
					</Text>
					<Text fontSize="xl">
						Feel free to contact us if you have any questions and
						suggestions
					</Text>
				</Container>
			</Box>
			<Box>
				<UnorderedList
					textAlign="center"
					listStyleType="none"
					color="macaron.blue_dark"
					m={0}
				>
					<ListItem display="inline" mr={8}>
						<Link href="/privacy">Privacy Policy</Link>
					</ListItem>
					<ListItem display="inline">
						<Link href="/terms">Terms and Conditions</Link>
					</ListItem>
				</UnorderedList>
			</Box>
		</Flex>
	);
};

export default About;
