import React, { useState } from 'react';
import {
	Box,
	Container,
	Heading,
	Text,
	UnorderedList,
	ListItem,
	Link,
} from '@chakra-ui/react';

const Terms = () => {
	const [website_name] = useState('Early Education Lab');
	const [company_name] = useState('Early Education Lab');
	const [website_url] = useState('www.earlyeducationlab.com');

	return (
		<Box bg="macaron.white" flex="1" overflow="auto" p={8}>
			<Container maxW="container.lg">
				<Heading as="h1" textAlign="center" mb={6}>
					Terms and Conditions
				</Heading>
				<Text fontSize="lg" mb={6}>
					Welcome to {website_name}!
				</Text>
				<Text fontSize="lg" mb={6}>
					These terms and conditions outline the rules and regulations
					for the use of {company_name} Website, located at
					{website_url}.
				</Text>
				<Text fontSize="lg" mb={6}>
					By accessing this website we assume you accept these terms
					and conditions. Do not continue to use {website_name} if you
					do not agree to take all of the terms and conditions stated
					on this page.
				</Text>
				<Text fontSize="lg" mb={6}>
					The following terminology applies to these Terms and
					Conditions, Privacy Statement and Disclaimer Notice and all
					Agreements: "Client", "You" and "Your" refers to you, the
					person log on this website and compliant to the Company’s
					terms and conditions. "The Company", "Ourselves", "We",
					"Our" and "Us", refers to our Company. "Party", "Parties",
					or "Us", refers to both the Client and ourselves. All terms
					refer to the offer, acceptance and consideration of payment
					necessary to undertake the process of our assistance to the
					Client in the most appropriate manner for the express
					purpose of meeting the Client’s needs in respect of
					provision of the Company’s stated services, in accordance
					with and subject to, prevailing law of Netherlands. Any use
					of the above terminology or other words in the singular,
					plural, capitalization and/or he/she or they, are taken as
					interchangeable and therefore as referring to same.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Cookies
				</Heading>
				<Text fontSize="lg" mb={6}>
					We employ the use of cookies. By accessing {website_name},
					you agreed to use cookies in agreement with the{' '}
					{company_name}'s Privacy Policy.
				</Text>
				<Text fontSize="lg" mb={6}>
					Most interactive websites use cookies to let us retrieve the
					user’s details for each visit. Cookies are used by our
					website to enable the functionality of certain areas to make
					it easier for people visiting our website. Some of our
					affiliate/advertising partners may also use cookies.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					License
				</Heading>
				<Text fontSize="lg" mb={6}>
					Unless otherwise stated, {company_name} and/or its licensors
					own the intellectual property rights for all material on{' '}
					{website_name}. All intellectual property rights are
					reserved. You may access this from {website_name} for your
					own personal use subjected to restrictions set in these
					terms and conditions.
				</Text>
				<Text fontSize="lg" mb={6}>
					You must not:
				</Text>
				<UnorderedList fontSize="lg" mb={6}>
					<ListItem>Republish material from {website_name}</ListItem>
					<ListItem>
						Sell, rent or sub-license material from {website_name}
					</ListItem>
					<ListItem>
						Reproduce, duplicate or copy material from{' '}
						{website_name}
					</ListItem>
					<ListItem>
						Redistribute content from {website_name}
					</ListItem>
				</UnorderedList>
				<Text fontSize="lg" mb={6}>
					This Agreement shall begin on the date hereof. Our Terms and
					Conditions were created with the help of the Terms And
					Conditions Generator.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Hyperlinking to our Content
				</Heading>
				<Text fontSize="lg" mb={6}>
					The following organizations may link to our Website without
					prior written approval:
				</Text>
				<UnorderedList fontSize="lg" mb={6}>
					<ListItem>Government agencies;</ListItem>
					<ListItem>Search engines;</ListItem>
					<ListItem>News organizations;</ListItem>
					<ListItem>
						Online directory distributors may link to our Website in
						the same manner as they hyperlink to the Websites of
						other listed businesses; and
					</ListItem>
					<ListItem>
						System wide Accredited Businesses except soliciting
						non-profit organizations, charity shopping malls, and
						charity fundraising groups which may not hyperlink to
						our Web site.
					</ListItem>
				</UnorderedList>
				<Text fontSize="lg" mb={6}>
					These organizations may link to our home page, to
					publications or to other Website information so long as the
					link: (a) is not in any way deceptive; (b) does not falsely
					imply sponsorship, endorsement or approval of the linking
					party and its products and/or services; and (c) fits within
					the context of the linking party’s site.
				</Text>
				<Text fontSize="lg" mb={6}>
					We may consider and approve other link requests from the
					following types of organizations:
				</Text>
				<UnorderedList fontSize="lg" mb={6}>
					<ListItem>
						commonly-known consumer and/or business information
						sources;
					</ListItem>
					<ListItem>dot.com community sites;</ListItem>
					<ListItem>
						associations or other groups representing charities;
					</ListItem>
					<ListItem>online directory distributors;</ListItem>
					<ListItem>internet portals;</ListItem>
					<ListItem>
						accounting, law and consulting firms; and
					</ListItem>
					<ListItem>
						educational institutions and trade associations
					</ListItem>
				</UnorderedList>
				<Text fontSize="lg" mb={6}>
					We will approve link requests from these organizations if we
					decide that: (a) the link would not make us look unfavorably
					to ourselves or to our accredited businesses; (b) the
					organization does not have any negative records with us; (c)
					the benefit to us from the visibility of the hyperlink
					compensates the absence of {company_name}; and (d) the link
					is in the context of general resource information.
				</Text>
				<Text fontSize="lg" mb={6}>
					These organizations may link to our home page so long as the
					link: (a) is not in any way deceptive; (b) does not falsely
					imply sponsorship, endorsement or approval of the linking
					party and its products or services; and (c) fits within the
					context of the linking party’s site.
				</Text>
				<Text fontSize="lg" mb={6}>
					If you are one of the organizations listed in paragraph 2
					above and are interested in linking to our website, you must
					inform us by sending an e-mail to {company_name}. Please
					include your name, your organization name, contact
					information as well as the URL of your site, a list of any
					URLs from which you intend to link to our Website, and a
					list of the URLs on our site to which you would like to
					link. Wait 2-3 weeks for a response.
				</Text>
				<Text fontSize="lg" mb={6}>
					Approved organizations may hyperlink to our Website as
					follows:
				</Text>
				<UnorderedList fontSize="lg" mb={6}>
					<ListItem>By use of our corporate name; or</ListItem>
					<ListItem>
						By use of the uniform resource locator being linked to;
						or
					</ListItem>
					<ListItem>
						By use of any other description of our Website being
						linked to that makes sense within the context and format
						of content on the linking party’s site.
					</ListItem>
				</UnorderedList>
				<Heading as="h1" size="md" mb={6}>
					iFrames
				</Heading>
				<Text fontSize="lg" mb={6}>
					Without prior approval and written permission, you may not
					create frames around our Webpages that alter in any way the
					visual presentation or appearance of our Website.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Content Liability
				</Heading>
				<Text fontSize="lg" mb={6}>
					We shall not be hold responsible for any content that you
					create on {''}
					{website_name} Website. You agree to protect and defend us
					against all: ; claims that is rising on your creation. No
					link(s) should appear on any creation that may be
					interpreted as libelous, obscene or criminal, or which
					infringes, otherwise violates, or advocates the infringement
					or other violation of, any third party rights.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Your Privacy
				</Heading>
				<Text fontSize="lg" mb={6}>
					Please read{' '}
					<Link href="/privacy" color="macaron.blue_dark">
						Privacy Policy
					</Link>
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Reservation of Rights
				</Heading>
				<Text fontSize="lg" mb={6}>
					We reserve the right to request that you remove all links or
					any particular link to our Website. You approve to
					immediately remove all links to our Website upon request. We
					also reserve the right to amen these terms and conditions
					and it’s linking policy at any time. By continuously linking
					to our Website, you agree to be bound to and follow these
					linking terms and conditions.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Removal of links from our website
				</Heading>
				<Text fontSize="lg" mb={6}>
					If you find any link on our Website that is offensive for
					any reason, you are free to contact and inform us any
					moment. We will consider requests to remove links but we are
					not obligated to or so or to respond to you directly.
				</Text>
				<Text fontSize="lg" mb={6}>
					We do not ensure that the information on this website is
					correct, we do not warrant its completeness or accuracy; nor
					do we promise to ensure that the website remains available
					or that the material on the website is kept up to date.
				</Text>
				<Heading as="h1" size="md" mb={6}>
					Disclaimer
				</Heading>
				<Text fontSize="lg" mb={6}>
					To the maximum extent permitted by applicable law, we
					exclude all representations, warranties and conditions
					relating to our website and the use of this website. Nothing
					in this disclaimer will:
				</Text>
				<UnorderedList fontSize="lg" mb={6}>
					<ListItem>
						limit or exclude our or your liability for death or
						personal injury;
					</ListItem>
					<ListItem>
						limit or exclude our or your liability for fraud or
						fraudulent misrepresentation;
					</ListItem>
					<ListItem>
						limit any of our or your liabilities in any way that is
						not permitted under applicable law; or
					</ListItem>
					<ListItem>
						exclude any of our or your liabilities that may not be
						excluded under applicable law.
					</ListItem>
				</UnorderedList>
				<Text fontSize="lg" mb={6}>
					The limitations and prohibitions of liability set in this
					Section and elsewhere in this disclaimer: (a) are subject to
					the preceding paragraph; and (b) govern all liabilities
					arising under the disclaimer, including liabilities arising
					in contract, in tort and for breach of statutory duty.
				</Text>
				<Text fontSize="lg" mb={6}>
					As long as the website and the information and services on
					the website are provided free of charge, we will not be
					liable for any loss or damage of any nature.
				</Text>
			</Container>
		</Box>
	);
};

export default Terms;
