import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
	Box,
	Flex,
	VStack,
	Divider,
	SimpleGrid,
	Radio,
	RadioGroup,
} from '@chakra-ui/react';
import {
	Row,
	HeadingCol,
	IconButtonCol,
	AddTextCol,
	AddShapeCol,
	ImageReaderCol,
	PresetCols,
} from '../Features';
import {
	addMultipleImageCopiesOrdered,
	addMultipleImageCopiesUnordered,
} from '../../fabricCanvas';
import CountContext from './CountContext';
import { sport, dinosaur, fruit } from './objectArray';
import { Ordered, Unordered } from '../../icons';
import { SubNavContext } from '../SubNav';

const CountAdd = () => {
	const [minCount] = useState(1);
	const [maxCount] = useState(10);
	const [countArray, setCountArray] = useState([]);
	const [imgaeArray, setImageArray] = useState([]);
	const [objectType, setObjectType] = useState('all');
	const { canvas } = useContext(CountContext);
	const { isExtend } = useContext(SubNavContext);

	//Fisher–Yates Shuffle
	const shuffle = (array) => {
		var m = array.length,
			t,
			i;
		while (m) {
			i = Math.floor(Math.random() * m--);
			t = array[m];
			array[m] = array[i];
			array[i] = t;
		}
		return array;
	};

	const setImageArrayByObjectType = useCallback(() => {
		switch (objectType) {
			case 'all':
				setImageArray(shuffle([...sport, ...dinosaur, ...fruit]));
				break;
			case 'sport':
				setImageArray(shuffle([...sport]));
				break;
			case 'fruit':
				setImageArray(shuffle([...fruit]));
				break;
			case 'dino':
				setImageArray(shuffle([...dinosaur]));
				break;
			default:
				setImageArray(shuffle([...sport, ...dinosaur, ...fruit]));
		}
	}, [objectType]);

	const setCountArrayFromMinToMax = useCallback(() => {
		const minToMaxArray = [...Array(maxCount - minCount + 1).keys()].map(
			(i) => i + minCount
		);
		setCountArray(shuffle(minToMaxArray));
	}, [minCount, maxCount]);

	useEffect(() => {
		setImageArrayByObjectType();
	}, [setImageArrayByObjectType]);

	useEffect(() => {
		setCountArrayFromMinToMax();
	}, [setCountArrayFromMinToMax]);

	useEffect(() => {
		if (countArray.length === 0) {
			setCountArrayFromMinToMax();
		}
		if (imgaeArray.length === 0) {
			setImageArrayByObjectType();
		}
	}, [countArray, imgaeArray]); //eslint-disable-line react-hooks/exhaustive-deps

	const handleObjectButton = (ordered, withBorder) => {
		const func = ordered
			? addMultipleImageCopiesOrdered
			: addMultipleImageCopiesUnordered;
		if (objectType === 'custom') {
			const activeObjects = canvas.getActiveObjects();
			if (activeObjects.length > 0 && activeObjects[0].type === 'image') {
				func(canvas, activeObjects[0].getSrc(), {
					width: 40,
					count: countArray[0],
					withBorder: withBorder,
				});
				setCountArray(countArray.slice(1));
			} else {
				return;
			}
		} else {
			func(canvas, `/assets/images${imgaeArray[0]}`, {
				width: 40,
				count: countArray[0],
				withBorder: withBorder,
			});
			setCountArray(countArray.slice(1));
			setImageArray(imgaeArray.slice(1));
		}
	};

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<HeadingCol text="Preset" />
					<PresetCols.Name canvas={canvas} />
					<PresetCols.Class canvas={canvas} />
					<PresetCols.PageBorder canvas={canvas} />
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Basic" />
					<AddTextCol canvas={canvas} />
					<AddShapeCol canvas={canvas} />
					<ImageReaderCol canvas={canvas} />
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Objects (1-10)" />
					<Box flex={`0 0 calc(100% - 4px)`} p={2}>
						<RadioGroup
							value={objectType}
							colorScheme="macaron_blue"
							bg="white"
							borderRadius="md"
							p={4}
							onChange={setObjectType}
						>
							<SimpleGrid columns={isExtend ? 4 : 2} spacing={2}>
								<Radio value="all">All</Radio>
								<Radio value="sport">Sport</Radio>
								<Radio value="fruit">Fruit</Radio>
								<Radio value="dino">Dino</Radio>
								<Radio value="custom">Custom</Radio>
							</SimpleGrid>
						</RadioGroup>
					</Box>
					<IconButtonCol
						width="120px"
						label="ordered without_border"
						icon={<Ordered size={40} />}
						onClick={() => handleObjectButton(true, false)}
					/>
					<IconButtonCol
						width="120px"
						label="unordered without_border"
						icon={<Unordered size={40} />}
						onClick={() => handleObjectButton(false, false)}
					/>
					<IconButtonCol
						width="120px"
						label="ordered with_border"
						icon={
							<Box border="2px solid currentColor" p="1px">
								<Ordered size={40} />
							</Box>
						}
						onClick={() => handleObjectButton(true, true)}
					/>
					<IconButtonCol
						width="120px"
						label="unordered with_border"
						icon={
							<Box border="2px solid currentColor" p="1px">
								<Unordered size={40} />
							</Box>
						}
						onClick={() => handleObjectButton(false, true)}
					/>
				</Row>
			</VStack>
		</Flex>
	);
};

export default CountAdd;
