import React from 'react';

export const SendToBack = ({ size = 16 }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
		>
			<path
				d="M11,12H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h10c0.6,0,1,0.4,1,1v10C12,11.6,11.6,12,11,12z"
				fill="#90ccf4"
			/>
			<path
				d="M14.5,15.5h-9c-0.6,0-1-0.4-1-1v-9c0-0.6,0.4-1,1-1h9c0.6,0,1,0.4,1,1v9C15.5,15.1,15.1,15.5,14.5,15.5z"
				fill="#FFFFFF"
				stroke="#000000"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};
