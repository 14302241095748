import React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
} from '@chakra-ui/react';
import { SketchPicker } from 'react-color';

export const ColorPickerCol = ({ canvas, type, value, setValue }) => {
	if (type !== 'fill' && type !== 'stroke') {
		throw new Error(`"${type}" type does not exist`);
	}

	const onChange = (color) => {
		const { r, g, b, a } = color.rgb;
		setValue(`rgba(${r}, ${g}, ${b}, ${a})`);
		const activeObject = canvas.getActiveObjects()[0];
		if (activeObject && type in activeObject) {
			canvas.getObjects().forEach((obj) => {
				if (obj.uid === activeObject.uid) {
					type === 'fill'
						? obj.set({ fill: `rgba(${r}, ${g}, ${b}, ${a})` })
						: obj.set({ stroke: `rgba(${r}, ${g}, ${b}, ${a})` });
				}
			});
			canvas.renderAll();
		}
	};

	return (
		<Box flex="0 0 120px" p={2}>
			<Popover>
				<PopoverTrigger>
					<IconButton
						bg="white"
						aria-label={type}
						icon={
							<svg
								width="100%"
								height="100%"
								style={{
									backgroundImage:
										"url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=')",
								}}
							>
								<rect width="100%" height="100%" fill={value} />
							</svg>
						}
						p={2}
					/>
				</PopoverTrigger>
				<PopoverContent w="fit-content">
					<PopoverArrow />
					<SketchPicker
						color={value}
						onChange={onChange}
						styles={{
							default: {
								picker: {
									boxShadow: 'none',
								},
							},
						}}
					/>
				</PopoverContent>
			</Popover>
		</Box>
	);
};
