import React from 'react';

export const DuplicateDeep = ({ size = 16, color = 'currentColor' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
		>
			<path
				fill="none"
				stroke={color}
				d="m 3.4000217,2.4000229 h 7.1999663 c 1.107995,0 1.999991,0.8919964 1.999991,1.9999919 v 9.1999622 c 0,1.107995 -0.891996,1.999992 -1.999991,1.999992 H 3.4000217 c -1.1079948,0 -1.9999907,-0.891997 -1.9999907,-1.999992 V 4.4000148 c 0,-1.1079955 0.8919959,-1.9999919 1.9999907,-1.9999919 z m -2e-7,0 v 0 c 0,-1.1079955 0.8919958,-1.99999186 1.999991,-1.99999186 v 0 h 7.1999665 c 1.107995,0 1.99999,0.89199636 1.99999,1.99999186 v 9.1999621 c 0,1.107995 -0.891995,1.999991 -1.99999,1.999991 v 0"
			/>
			<text
				fill={color}
				stroke="none"
				fontSize={8}
				x="3.76175"
				y="11.908406"
			>
				<tspan x="3.76175" y="11.908406">
					D
				</tspan>
			</text>
		</svg>
	);
};
