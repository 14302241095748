import React from 'react';
import { Box, Flex, Text, Switch } from '@chakra-ui/react';

export const CenteredScalingCol = ({ canvas, value, setValue }) => {
	const onChange = () => {
		if (!value) {
			canvas.getObjects().forEach((obj) => {
				obj.setOrigin('center', 'center', true);
			});
		} else {
			canvas.getObjects().forEach((obj) => {
				obj.setOrigin('left', 'top', false);
			});
		}
		setValue(!value);
	};

	return (
		<Box flex="0 0 100%" p={2}>
			<Flex align="center" justify="space-between" h="40px">
				<Text>Centered Scaling</Text>
				<Switch isChecked={value} onChange={onChange} />
			</Flex>
		</Box>
	);
};
