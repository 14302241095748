import React from 'react';
import { Box, Select } from '@chakra-ui/react';
import { TRACE_FONT_OPTIONS } from '../../fonts/fontOptions';
import { colors } from '../../index';

export const FontFamilyCol = ({
	canvas,
	fontFamily,
	setFontFamily,
	options,
}) => {
	const onSelectChange = (e) => {
		setFontFamily(e.target.value);
		const activeObject = canvas.getActiveObjects()[0];
		if (activeObject && 'fontFamily' in activeObject) {
			canvas.getObjects().forEach((obj) => {
				if (obj.uid === activeObject.uid) {
					obj.set({ fontFamily: e.target.value });
				}
			});
			canvas.renderAll();
		}
	};

	return (
		<Box flex="0 0 240px" p={2}>
			<Select
				bg="white"
				value={fontFamily}
				onChange={onSelectChange}
				fontFamily={fontFamily}
				fontWeight={
					fontFamily.substring(0, 2) === 'KG' ? 'bold' : 'normal'
				}
			>
				{options.map((option, key) => (
					<option
						key={key}
						style={{
							fontFamily: option,
							fontWeight:
								option.substring(0, 2) === 'KG' && 'bold',
							backgroundColor:
								TRACE_FONT_OPTIONS.includes(option) &&
								colors.macaron.blue_light,
						}}
					>
						{option}
					</option>
				))}
			</Select>
		</Box>
	);
};
