import React from 'react';

export const Count123 = ({ size = 16, color = 'currentColor', ...props }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit="2"
			fill={color}
			className={props.className}
			style={props.style}
		>
			<text
				x="-1.394px"
				y="5.941px"
				fontFamily="ChalkboardSE-Regular, Chalkboard SE, cursive"
				fontSize="8.946px"
			>
				1
			</text>
			<text
				x="4.261px"
				y="10.066px"
				fontFamily="ChalkboardSE-Regular, Chalkboard SE, cursive"
				fontSize="10.284px"
			>
				2
			</text>
			<text
				x="10.078px"
				y="15.843px"
				fontFamily="ChalkboardSE-Regular, Chalkboard SE, cursive"
				fontSize="11.83px"
			>
				3
			</text>
		</svg>
	);
};
