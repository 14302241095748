import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import LeftNav from './components/LeftNav';
import Home from './components/Home';
import Trace from './components/Trace/Trace';
import Count from './components/Count/Count';
import Cutout from './components/Cutout/Cutout';
import About from './components/About';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

import './fonts/fonts.css';

function App() {
	const [fontsLoaded, setFontsLoaded] = useState(false);

	useEffect(() => {
		const loadFonts = (fontNames) => {
			const FontFaceObserver = require('fontfaceobserver');
			return Promise.all(
				fontNames.map((fontName) =>
					new FontFaceObserver(fontName).load()
				)
			);
		};
		loadFonts([
			'KG Primary Dots',
			'KG Primary Dots Lined',
			'Architects Daughter',
			'Fredoka One',
			'Roboto',
		]).then(() => setFontsLoaded(true));
	}, []);

	return (
		<React.Fragment>
			{fontsLoaded && (
				<BrowserRouter>
					<LeftNav />
					<Switch>
						<Route path={'/'} exact>
							<Home />
						</Route>
						<Route path={'/trace'} exact>
							<Trace />
						</Route>
						<Route path={'/count'} exact>
							<Count />
						</Route>
						<Route path={'/cutout'} exact>
							<Cutout />
						</Route>
						<Route path={'/about'} exact>
							<About />
						</Route>
						<Route path={'/privacy'} exact>
							<Privacy />
						</Route>
						<Route path={'/terms'} exact>
							<Terms />
						</Route>
						<Route render={() => <Redirect to="/" />} />
					</Switch>
				</BrowserRouter>
			)}
		</React.Fragment>
	);
}

export default App;
