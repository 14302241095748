import React from 'react';

export const TIcon = ({ size = 16, color = 'currentColor' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill={color}
			viewBox="0 0 16 16"
		>
			<path
				d="M12.9,2L13,4.8h-0.3c-0.1-0.5-0.2-0.8-0.3-1.1C12.2,3.4,12,3.2,11.7,3c-0.3-0.2-0.7-0.2-1.2-0.2H8.8v9.1
			c0,0.7,0.1,1.2,0.2,1.4c0.2,0.2,0.6,0.4,1,0.4h0.4V14H5.4v-0.3h0.4c0.5,0,0.9-0.2,1.1-0.5c0.1-0.2,0.2-0.6,0.2-1.3V2.8H5.7
			c-0.6,0-1,0-1.2,0.1C4.2,3,3.9,3.2,3.7,3.6S3.4,4.3,3.3,4.8H3L3.1,2H12.9z"
			/>
		</svg>
	);
};

export const ShapesIcon = ({
	size = 16,
	color = 'currentColor',
	dash = false,
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			stroke={color}
			viewBox="0 0 16 16"
			strokeDasharray={dash ? '0.5' : null}
			strokeWidth="0.5"
		>
			<path d="M15.5,1.5h-9v2.2C8,4.2,9.1,5.5,9.4,7.1l0.7-1.2l2.9,4.9h2.5V1.5z" />
			<path d="M6.5,3.7C6,3.5,5.5,3.4,5,3.4c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5c0.5,0,1-0.1,1.5-0.3l0.8-1.4" />
			<path d="M6.5,12.2l-1.4,2.3h10L13,10.8" />
			<path d="M9.4,7.1l-2.1,3.7" />
		</svg>
	);
};

export const ImageIcon = ({ size = 16, color = 'currentColor' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			stroke={color}
			viewBox="0 0 16 16"
			strokeWidth="0.5"
		>
			<path d="M5.7,6.1l3.8,3.8l2.2-2.2c0.2-0.2,0.5-0.2,0.7,0l3.4,3.4V2.2H0.2v8.5L5,6.1C5.2,5.9,5.5,5.9,5.7,6.1z" />
			<path d="M5.7,13.8H0.2v-3" />
			<polyline points="15.8,11.1 15.8,13.8 10.7,13.8 	" />
			<polyline points="9.5,9.9 5.7,13.7 5.7,13.8 10.7,13.8 	" />
			<circle cx="12.5" cy="4.5" r="0.8" />
		</svg>
	);
};
