import React, { useState } from 'react';
import {
	Flex,
	VStack,
	Divider,
	Button,
	Text,
	Input,
	Select,
} from '@chakra-ui/react';
import { Row } from './Row';

const imgToPDF = (img) =>
	new Promise((resolve, reject) => {
		try {
			const PDFDocument = require('pdfkit/js/pdfkit.standalone.js');
			const blobStream = require('blob-stream');
			const doc = new PDFDocument();
			const stream = doc.pipe(blobStream());
			doc.image(img, 0, 0, {
				width: doc.page.width,
				height: doc.page.height,
			});
			doc.end();
			stream.on('finish', () => {
				resolve(stream.toBlob('application/pdf'));
			});
		} catch (e) {
			reject(e);
		}
	});

const saveAs = (url, filename) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	a.href = url;
	a.download = filename;
	a.click();
	document.body.removeChild(a);
};

export const DownloadForm = ({ canvas = null }) => {
	const [filename, setFilename] = useState('');
	const [pngDimensions, setPngDimensions] = useState(1700);
	const [pdfQuality, setPdfQuality] = useState(2);

	const onFilenameChange = (e) => {
		setFilename(e.target.value);
	};

	const onPngDimensionsChange = (e) => {
		setPngDimensions(e.target.value);
	};

	const onPdfQualityChange = (e) => {
		setPdfQuality(e.target.value);
	};

	/**
	 * Function that creates donwload of PNG file of the canvas
	 * dpi = pngDimensions / 8.5
	 * 1 / 72 convert 612 x 792 to 8.5 x 11 inches
	 */
	const onPngClick = (e) => {
		e.target.disabled = true;
		const img = canvas.toDataURL({
			format: 'png',
			multiplier: pngDimensions / 8.5 / 72 / canvas.getZoom(),
		});
		saveAs(img, `${filename ? filename : 'file'}.png`);
		e.target.disabled = false;
	};

	/**
	 * Function that creates donwload of PDF file of the canvas
	 */
	const onPdfClick = (e) => {
		e.target.disabled = true;
		const img = canvas.toDataURL({
			format: 'png',
			multiplier: (pdfQuality * 96) / 72 / canvas.getZoom(),
		});
		imgToPDF(img).then((blob) => {
			const url = window.URL.createObjectURL(blob);
			saveAs(url, `${filename ? filename : 'file'}.pdf`);
			setTimeout(function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(url);
			}, 100);
			e.target.disabled = false;
		});
	};

	/**
	 * Function that creates donwload of JSON file of the fabric cavans
	 */
	const onJSONClick = (e) => {
		e.target.disabled = true;
		let json = canvas.toJSON([
			'uid',
			'centeredScaling',
			'_controlsVisibility',
		]);
		const blob = new Blob([JSON.stringify(json)], {
			type: 'application/json',
		});
		const url = window.URL.createObjectURL(blob);
		saveAs(url, `${filename ? filename : 'file'}.json`);
		e.target.disabled = false;
	};

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<Flex direction="column" flex="0 0 240px" p={2}>
						<Text mb={2}>Filename</Text>
						<Input
							bg="white"
							value={filename}
							onChange={onFilenameChange}
						/>
					</Flex>
				</Row>
				<Divider />
				<Row>
					<Flex direction="column" flex="0 0 240px" p={2}>
						<Text mb={2}>Image Dimensions</Text>
						<Select
							bg="white"
							value={pngDimensions}
							onChange={onPngDimensionsChange}
						>
							<option value="850">850 x 1100</option>
							<option value="1700">1700 x 2200</option>
							<option value="2550">2550 x 3300</option>
						</Select>
					</Flex>
					<Flex direction="column" flex="0 0 240px" p={2}>
						<Text mb={2}>PDF Quality</Text>
						<Select
							bg="white"
							value={pdfQuality}
							onChange={onPdfQualityChange}
						>
							<option value="1">Low</option>
							<option value="2">Medium</option>
							<option value="3">High</option>
						</Select>
					</Flex>
				</Row>
			</VStack>
			<Row p={2}>
				<Flex direction="column" flex="0 0 120px" p={2}>
					<Button colorScheme="macaron_blue" onClick={onPngClick}>
						PNG
					</Button>
				</Flex>
				<Flex direction="column" flex="0 0 120px" p={2}>
					<Button colorScheme="macaron_blue" onClick={onPdfClick}>
						PDF
					</Button>
				</Flex>
				{process.env.NODE_ENV === 'development' && (
					<Flex direction="column" flex="0 0 120px" p={2}>
						<Button
							colorScheme="macaron_blue"
							onClick={onJSONClick}
						>
							JSON
						</Button>
					</Flex>
				)}
			</Row>
		</Flex>
	);
};
