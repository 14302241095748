import React, { useContext } from 'react';
import {
	Box,
	Flex,
	VStack,
	IconButton,
	AspectRatio,
	Divider,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
} from '@chakra-ui/react';
import {
	Row,
	HeadingCol,
	IconButtonCol,
	AddTextCol,
	AddShapeCol,
	ImageReaderCol,
	PresetCols,
} from '../Features';
import {
	addIText,
	addEllipse,
	addRect,
	addTriangle,
	addStar,
} from '../../fabricCanvas';
import {
	CircleIcon,
	SquareIcon,
	TriangleIcon,
	StarIcon,
	ShapesIcon,
} from '../../icons';
import TraceContext from './TraceContext';

const TraceAdd = () => {
	const { canvas } = useContext(TraceContext);

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<HeadingCol text="Preset" />
					<PresetCols.Name canvas={canvas} />
					<PresetCols.Class canvas={canvas} />
					<PresetCols.PageBorder canvas={canvas} />
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Basic" />
					<AddTextCol canvas={canvas} />
					<AddShapeCol canvas={canvas} />
					<ImageReaderCol canvas={canvas} />
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Traceable" />
					<IconButtonCol
						width="120px"
						label="add traceable text"
						icon={
							<svg width={60} height={60} viewBox="0 0 16 16">
								<text
									x="50%"
									y="14px"
									textAnchor="middle"
									fontSize="20px"
									fontFamily="KG Primary Dots"
									fontWeight="normal"
								>
									T
								</text>
							</svg>
						}
						onClick={() =>
							addIText(canvas, {
								fontFamily: 'KG Primary Dots',
								fontSize: 70,
							})
						}
					/>
					<Box flex="0 0 120px" p={2}>
						<Popover>
							<PopoverTrigger>
								<AspectRatio w="100%" ratio={1}>
									<IconButton
										w="100%"
										h="100%"
										bg="white"
										aria-label="add traceable shape"
										icon={<ShapesIcon size={60} dash />}
									/>
								</AspectRatio>
							</PopoverTrigger>
							<PopoverContent w="fit-content">
								<PopoverArrow />
								<Box w="256px">
									<Row>
										<IconButtonCol
											label="dash circle"
											icon={<CircleIcon dash />}
											onClick={() =>
												addEllipse(canvas, {
													dash: true,
												})
											}
										/>
										<IconButtonCol
											label="dash square"
											icon={<SquareIcon dash />}
											onClick={() =>
												addRect(canvas, { dash: true })
											}
										/>
										<IconButtonCol
											label="dash triangle"
											icon={<TriangleIcon dash />}
											onClick={() =>
												addTriangle(canvas, {
													dash: true,
												})
											}
										/>
										<IconButtonCol
											label="dash star"
											icon={<StarIcon dash />}
											onClick={() =>
												addStar(canvas, { dash: true })
											}
										/>
									</Row>
								</Box>
							</PopoverContent>
						</Popover>
					</Box>
				</Row>
			</VStack>
		</Flex>
	);
};

export default TraceAdd;
