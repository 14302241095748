import React from 'react';

export const TraceE = ({ size = 16, color = 'currentColor', ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill={color}
			viewBox="0 0 16 16"
			stroke="none"
			className={props.className}
			style={props.style}
		>
			<circle cx="2.75" cy="1" r="1" />
			<circle cx="6.25" cy="1" r="1" />
			<circle cx="9.75" cy="1" r="1" />
			<circle cx="13.25" cy="1" r="1" />
			<circle cx="2.75" cy="15" r="1" />
			<circle cx="6.25" cy="15" r="1" />
			<circle cx="9.75" cy="15" r="1" />
			<circle cx="13.25" cy="15" r="1" />
			<circle cx="2.75" cy="8" r="1" />
			<circle cx="6.25" cy="8" r="1" />
			<circle cx="9.75" cy="8" r="1" />
			<circle cx="2.75" cy="4.5" r="1" />
			<circle cx="2.75" cy="11.5" r="1" />
		</svg>
	);
};
