import React, { useState, useRef } from 'react';
import { Button, IconButton, AspectRatio, Tooltip } from '@chakra-ui/react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react';
import FloatPane from '../StandaloneComponents/FloatPane';

import { v4 as uuidv4 } from 'uuid';

import { BsTrash } from 'react-icons/bs';
import {
	Clear,
	BringToFront,
	SendToBack,
	Duplicate,
	DuplicateDeep,
} from '../../icons';

import { colors } from '../../index';

const EditorButton = ({ label, icon, onClick }) => (
	<Tooltip label={label}>
		<AspectRatio w="100%" ratio={1}>
			<IconButton
				w="100%"
				h="100%"
				bg="white"
				borderRadius={0}
				aria-label={label}
				icon={icon}
				onClick={onClick}
			/>
		</AspectRatio>
	</Tooltip>
);

export const FloatEditor = ({ canvas }) => {
	const [isOpen, setIsOpen] = useState(false);
	const cancelRef = useRef();

	return (
		<FloatPane
			className="eel-float-editor"
			initialLeft={100}
			initialTop={0}
			width={42}
			barThickness={16}
			barColor={colors.macaron.blue}
		>
			<EditorButton
				label="clear all"
				icon={<Clear />}
				onClick={() => setIsOpen(true)}
			/>
			<AlertDialog
				leastDestructiveRef={cancelRef}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			>
				<AlertDialogOverlay>
					<AlertDialogContent w="90%">
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Clear All
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure you want to clear all?
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								onClick={() => setIsOpen(false)}
							>
								Cancel
							</Button>
							<Button
								colorScheme="red"
								onClick={() => {
									canvas.remove(
										...canvas
											.getObjects()
											.filter(
												(obj) => obj.as !== 'cut_grid'
											)
									);
									setIsOpen(false);
								}}
								ml={3}
							>
								Clear
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<EditorButton
				label="delete"
				icon={<BsTrash />}
				onClick={() => canvas.remove(...canvas.getActiveObjects())}
			/>
			<EditorButton
				label="bring to front"
				icon={<BringToFront />}
				onClick={() => {
					let activeObjects = canvas.getActiveObjects();
					activeObjects.length > 0 &&
						canvas.bringToFront(activeObjects[0]);
				}}
			/>
			<EditorButton
				label="send to back"
				icon={<SendToBack />}
				onClick={() => {
					let activeObjects = canvas.getActiveObjects();
					activeObjects.length > 0 &&
						canvas.sendToBack(activeObjects[0]);
				}}
			/>
			<EditorButton
				label="duplicate"
				icon={<Duplicate />}
				onClick={() => {
					let activeObjects = canvas.getActiveObjects();
					activeObjects.length > 0 &&
						activeObjects[0].clone(
							(cloned) => {
								canvas.discardActiveObject();
								cloned.top += 50;
								cloned.uid = uuidv4();
								canvas.add(cloned);
								canvas.setActiveObject(cloned);
							},
							['centeredScaling', '_controlsVisibility']
						);
				}}
			/>
			<EditorButton
				label="deep duplicate"
				icon={<DuplicateDeep />}
				onClick={() => {
					let activeObjects = canvas.getActiveObjects();
					activeObjects.length > 0 &&
						activeObjects[0].clone(
							(cloned) => {
								canvas.discardActiveObject();
								cloned.top += 50;
								canvas.add(cloned);
								canvas.setActiveObject(cloned);
							},
							['uid', 'centeredScaling', '_controlsVisibility']
						);
				}}
			/>
		</FloatPane>
	);
};
