export const sport = [
	'/sport/basketball.svg',
	'/sport/soccer.svg',
	'/sport/football.svg',
	'/sport/baseball.svg',
	'/sport/plain_volleyball.svg',
	'/sport/colored_volleyball.svg',
	'/sport/tennis.svg',
	'/sport/bowling.svg',
	'/sport/trophy.svg',
	'/sport/badminton.svg',
];

export const dinosaur = [
	'/dinosaur/dinosaur_1.svg',
	'/dinosaur/dinosaur_2.svg',
	'/dinosaur/dinosaur_3.svg',
	'/dinosaur/dinosaur_4.svg',
	'/dinosaur/dinosaur_5.svg',
	'/dinosaur/dinosaur_6.svg',
	'/dinosaur/dinosaur_7.svg',
	'/dinosaur/dinosaur_8.svg',
	'/dinosaur/dinosaur_9.svg',
	'/dinosaur/dinosaur_10.svg',
];

export const fruit = [
	'/fruit/fruit_1.svg',
	'/fruit/fruit_2.svg',
	'/fruit/fruit_3.svg',
	'/fruit/fruit_4.svg',
	'/fruit/fruit_5.svg',
	'/fruit/fruit_6.svg',
	'/fruit/fruit_7.svg',
	'/fruit/fruit_8.svg',
	'/fruit/fruit_9.svg',
	'/fruit/fruit_10.svg',
	'/fruit/fruit_11.svg',
	'/fruit/fruit_12.svg',
];
