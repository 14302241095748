import React from 'react';

export const Clear = ({ size = 16, color = 'currentColor' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
		>
			<path
				fill={color}
				stroke="none"
				d="M 2,5 H 7 V 0.8 H 9 V 5 h 5 V 8.4 H 2 Z m 8.79,10.079 v -3.66783 c 0,-0.0893 -0.06449,-0.16244 -0.144,-0.16244 H 9.979 c -0.07945,0 -0.145,0.0731 -0.145,0.16244 V 15.079 H 6.166 v -3.66783 c 0,-0.0893 -0.065498,-0.16244 -0.145,-0.16244 H 5.354 c -0.07945,0 -0.145,0.0731 -0.145,0.16244 V 15.079 H 2.013 l 0.8155,-5.78 10.3405,4.9e-4 0.815,5.77951 z m 4.202595,0.30374 -0.957427,-6.20508 h 0.323357 c 0.260129,0 0.469684,-0.23553 0.469684,-0.52791 V 4.75127 c 0,-0.29239 -0.209555,-0.52792 -0.469684,-0.52792 H 9.9146433 V 0.52792 C 9.9146433,0.23553 9.7050891,0 9.4449598,0 H 6.5546267 C 6.2944974,0 6.0849519,0.23553 6.0849519,0.52792 V 4.22335 H 1.6410615 c -0.2601294,0 -0.4696748,0.23553 -0.4696748,0.52792 v 3.89848 c 0,0.29238 0.2095454,0.52791 0.4696748,0.52791 h 0.323357 L 1.007,15.38274 C 1.00175,15.41324 1,15.44364 1,15.47204 c 0,0.29239 0.2095455,0.52792 0.4696749,0.52792 H 14.530367 c 0.02712,0 0.05416,-0.002 0.07945,-0.008 0.256515,-0.0487 0.42813,-0.32284 0.382971,-0.60914 z"
			/>
		</svg>
	);
};
