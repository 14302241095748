import React from 'react';

export const CircleIcon = ({
	size = 24,
	color = 'currentColor',
	dash = false,
}) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24">
			<circle
				cx="12"
				cy="12"
				r="11"
				fill="transparent"
				stroke={color}
				strokeWidth="2"
				strokeDasharray={dash ? '3' : null}
			/>
		</svg>
	);
};
export const SquareIcon = ({
	size = 24,
	color = 'currentColor',
	dash = false,
}) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24">
			<rect
				x="1"
				y="1"
				width="22"
				height="22"
				fill="transparent"
				stroke={color}
				strokeWidth="2"
				strokeDasharray={dash ? '3' : null}
			/>
		</svg>
	);
};

export const TriangleIcon = ({
	size = 24,
	color = 'currentColor',
	dash = false,
}) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24">
			<polygon
				points="12,1 1,23 23,23"
				fill="transparent"
				stroke={color}
				strokeWidth="2"
				strokeDasharray={dash ? '3' : null}
			/>
		</svg>
	);
};

export const StarIcon = ({
	size = 24,
	color = 'currentColor',
	dash = false,
}) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24">
			<path
				d="M12,1L15.39919,8.24195L23,9.40325L17.5,15.04032L18.79837,23L12,19.24194L5.20163,23L6.5,15.04032L1,9.40325L8.60081,8.24195L12,1Z"
				fill="transparent"
				stroke={color}
				strokeWidth="2"
				strokeDasharray={dash ? '2.6' : null}
			/>
		</svg>
	);
};

export const TextIcon = ({ size = 24, color = 'currentColor', text = 1 }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fontFamily="Fredoka One"
			fontSize="24"
			fill={color}
		>
			<text x="12" y="20" textAnchor="middle">
				{text}
			</text>
		</svg>
	);
};
