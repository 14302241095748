import { fabric } from 'fabric';
import { colors } from './';
import { v4 as uuidv4 } from 'uuid';

/**
 * Function that overrides fabric prototype
 */
const overrideFabricPrototype = () => {
	/**
	 * Fabric Object
	 */
	fabric.Object.prototype.objectCaching = false;
	fabric.Object.prototype.borderColor = colors.macaron.red;
	fabric.Object.prototype.cornerColor = colors.macaron.red;
	fabric.Object.prototype.cornerStyle = 'circle';
	fabric.Object.prototype.borderOpacityWhenMoving = 1;
	fabric.Object.prototype.lockScalingFlip = true;
	fabric.Object.prototype.setOrigin = function (
		originX,
		originY,
		centeredScaling = false
	) {
		this.canvas.getObjects().forEach((obj) => {
			if (obj.uid === this.uid) {
				const point = obj.getPointByOrigin(originX, originY);
				obj.set({
					originX: originX,
					originY: originY,
					centeredScaling: centeredScaling,
					left: point.x,
					top: point.y,
				});
			}
		});
	};
	/**
	 * Fabric IText
	 */
	fabric.IText.prototype.editingBorderColor = colors.macaron.red;
};

/**
 * Function that initialzes canvas
 * @param   {String} element   element to initialize instance on
 * @return  {Object}           fabric canvas object
 */
export const initializeCanvas = (element) => {
	const canvas = new fabric.Canvas(element, {
		backgroundColor: 'white',
		selection: false,
		perPixelTargetFind: true,
		targetFindTolerance: 8,
		preserveObjectStacking: true,
	});
	overrideFabricPrototype();
	return canvas;
};

/**
 * Function that adds fabric Text
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {String} text         text
 * @param   {Number} fontSize     font size
 * @param   {String} fontFamily   font family
 * @param   {String} fill         fill color in rgba, example: 'rgba(0,0,0,0)'
 * @param   {String} stroke       stroke color in rgba, example: 'rgba(0,0,0,0)'
 * @param   {Number} left         left
 * @param   {Number} top          top
 */
export const addText = (canvas, config = {}) => {
	const {
		text = 'New Text',
		fontSize = 24,
		fontFamily = 'Times New Roman',
		fill = 'rgba(0,0,0,1)',
		stroke = 'rgba(0,0,0,0)',
		left = 30,
		top = 30,
	} = config;
	const newText = new fabric.Text(text, {
		uid: uuidv4(),
		left: left,
		top: top,
		fontSize: fontSize,
		fontFamily: fontFamily,
		fill: fill,
		stroke: stroke,
	});
	canvas.add(newText);
	canvas.setActiveObject(newText);
};

/**
 * Function that adds fabric IText
 * @param   {Object} canvas       target canvas
 * @param   {Object} config       includes follow parameters
 * @param   {String} text         text
 * @param   {Number} fontSize     font size
 * @param   {String} fontFamily   font family
 * @param   {String} fill         fill color in rgba, example: 'rgba(0,0,0,0)'
 * @param   {String} stroke       stroke color in rgba, example: 'rgba(0,0,0,0)'
 * @param   {Number} left         left
 * @param   {Number} top          top
 */
export const addIText = (canvas, config = {}) => {
	const {
		text = 'New Text',
		fontSize = 24,
		fontFamily = 'Times New Roman',
		fill = 'rgba(0,0,0,1)',
		stroke = 'rgba(0,0,0,0)',
		left = 30,
		top = 30,
	} = config;
	const newText = new fabric.IText(text, {
		uid: uuidv4(),
		left: left,
		top: top,
		fontSize: fontSize,
		fontFamily: fontFamily,
		fill: fill,
		stroke: stroke,
	});
	canvas.add(newText);
	canvas.setActiveObject(newText);
};

/**
 * Function that adds fabric Ellipse
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {number} rx       horizontal radius
 * @param   {number} ry       vertical radius
 * @param   {boolean} dash    stroke dash
 */
export const addEllipse = (canvas, config = {}) => {
	const { rx = 50, ry = 50, dash = false } = config;
	const ellipse = new fabric.Ellipse({
		uid: uuidv4(),
		left: 30,
		top: 30,
		rx: rx,
		ry: ry,
		stroke: 'rgba(0,0,0,1)',
		strokeWidth: 3,
		fill: 'rgba(0,0,0,0)',
		strokeDashArray: dash ? [3, 5] : null,
	});
	canvas.add(ellipse);
	canvas.setActiveObject(ellipse);
};

/**
 * Function that adds fabric Rectangle
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {number} width    rectangle width
 * @param   {number} height   rectangle height
 * @param   {boolean} dash    stroke dash
 * @param   {Number} left         left
 * @param   {Number} top          top
 */
export const addRect = (canvas, config = {}) => {
	const {
		width = 100,
		height = 100,
		dash = false,
		left = 30,
		top = 30,
	} = config;
	const rect = new fabric.Rect({
		uid: uuidv4(),
		left: left,
		top: top,
		width: width,
		height: height,
		stroke: 'rgba(0,0,0,1)',
		strokeWidth: 3,
		fill: 'rgba(0,0,0,0)',
		strokeDashArray: dash ? [3, 5] : null,
	});
	canvas.add(rect);
	canvas.setActiveObject(rect);
};

/**
 * Function that adds fabric Triangle
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {number} width    triangle width
 * @param   {number} height   triangle height
 * @param   {boolean} dash    stroke dash
 */
export const addTriangle = (canvas, config = {}) => {
	const { width = 100, height = 100, dash = false } = config;
	const triangle = new fabric.Triangle({
		uid: uuidv4(),
		left: 30,
		top: 30,
		width: width,
		height: height,
		stroke: 'rgba(0,0,0,1)',
		strokeWidth: 3,
		fill: 'rgba(0,0,0,0)',
		strokeDashArray: dash ? [3, 5] : null,
	});
	canvas.add(triangle);
	canvas.setActiveObject(triangle);
};

/**
 * Function that adds Star
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {number} width    triangle width
 * @param   {number} height   triangle height
 * @param   {boolean} dash    stroke dash
 */
export const addStar = (canvas, config = {}) => {
	const { width = 100, height = 100, dash = false } = config;
	const star = new fabric.Path(
		'M12,1L15.39919,8.24195L23,9.40325L17.5,15.04032L18.79837,23L12,19.24194L5.20163,23L6.5,15.04032L1,9.40325L8.60081,8.24195L12,1Z'
	);
	const sx = width / star.width;
	const sy = height / star.height;
	star.set({
		uid: uuidv4(),
		left: 30,
		top: 30,
		scaleX: sx,
		scaleY: sy,
		stroke: 'rgba(0,0,0,1)',
		strokeWidth: 3,
		fill: 'rgba(0,0,0,0)',
		strokeDashArray: dash ? [3, 5] : null,
		strokeUniform: true,
	});
	canvas.add(star);
	canvas.setActiveObject(star);
};

/**
 * Function that adds image to canvas
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {number} width    image width
 * @param   {Number} left     left
 * @param   {Number} top      top
 */
export const addImage = (canvas, url, config = {}) => {
	if (!url) return;
	const { width = 100, left = 50, top = 50 } = config;
	fabric.Image.fromURL(url, (img) => {
		img.set({
			uid: uuidv4(),
			left: left,
			top: top,
			scaleX: width / img.width,
			scaleY: width / img.width,
		});
		canvas.add(img);
	});
};

/**
 * Function that adds svg to canvas
 * @param   {Object} canvas   target canvas
 * @param   {Object} config   includes follow parameters
 * @param   {number} width    image width
 * @param   {Number} left     left
 * @param   {Number} top      top
 */
export const addSvg = (canvas, url, config = {}) => {
	if (!url) return;
	const { width = 100, left = 50, top = 50 } = config;
	fabric.loadSVGFromURL(url, function (objects, options) {
		let obj = fabric.util.groupSVGElements(objects, options);
		obj.set({
			uid: uuidv4(),
			left: left,
			top: top,
			scaleX: width / obj.width,
			scaleY: width / obj.width,
		});
		canvas.add(obj);
	});
};

/**
 * Function that adds multiple copies of image in order
 * @param   {Object} canvas         target canvas
 * @param   {Object} config         includes follow parameters
 * @param   {Number} count          number of duplicate image
 * @param   {number} width          image width
 * @param   {Number} left           left
 * @param   {Number} top            top
 * @param   {Boolean} withBorder    indicate with border around objects
 * @param   {Boolean} activeOnLoad  set it to active object on load
 */
export const addMultipleImageCopiesOrdered = (canvas, url, config = {}) => {
	if (!url) return;
	const {
		count = 3,
		width = 100,
		left = 50,
		top = 50,
		withBorder = false,
		activeOnLoad = true,
	} = config;
	const imageData = [];
	const imgObj = new Image();
	imgObj.src = url;
	imgObj.onload = () => {
		for (let i = 0; i < count; i++) {
			let image = new fabric.Image(imgObj);
			const height = (image.height * width) / image.width; //record the height after scaled
			image.set({
				scaleX: width / image.width,
				scaleY: width / image.width,
				left: left + (i % 5) * width * 1.2,
				top: top + Math.floor(i / 5) * height * 1.2,
			});
			imageData.push(image);
		}
		const group = new fabric.Group(imageData, {
			uid: uuidv4(),
		});
		if (withBorder) {
			const offset = 10;
			group.addWithUpdate(
				new fabric.Rect({
					left: left - offset,
					top: top - offset,
					width: group.width + 2 * offset,
					height: group.height + 2 * offset,
					strokeWidth: 2,
					stroke: 'rgba(0,0,0,1)',
					fill: 'rgba(0,0,0,0)',
				})
			);
		}
		canvas.add(group);
		if (activeOnLoad) canvas.setActiveObject(group);
	};
};

/**
 * Function that adds multiple copies of image not in order
 * @param   {Object} canvas         target canvas
 * @param   {Object} config         includes follow parameters
 * @param   {Number} count          number of duplicate image
 * @param   {number} width          image width
 * @param   {Number} left           left
 * @param   {Number} top            top
 * @param   {Boolean} withBorder    indicate with border around objects
 * @param   {Boolean} activeOnLoad  set it to active object on load
 */
export const addMultipleImageCopiesUnordered = (canvas, url, config = {}) => {
	if (!url) return;
	const {
		count = 3,
		width = 100,
		left = 50,
		top = 50,
		withBorder = false,
		activeOnLoad = true,
	} = config;
	const maxGroupWidth = 6 * width;
	const maxGroupHeight = 6 * width;
	const scaledWidth = Math.sqrt((Math.pow(width, 2) * 10) / count); // **less count longer width** to fill better inside the box
	const imageData = [];
	let protection = 100;
	const imgObj = new Image();
	imgObj.src = url;
	imgObj.onload = () => {
		while (imageData.length < count) {
			let image = new fabric.Image(imgObj);
			image.set({
				scaleX: scaledWidth / image.width,
				scaleY: scaledWidth / image.width,
			});
			//Generate random position of the image
			const scaledHeight = (image.height * scaledWidth) / image.width;
			const maxRandomX = maxGroupWidth - scaledWidth;
			const maxRandomY = maxGroupHeight - scaledHeight;
			let overlapping = false;
			let x = left + Math.floor(Math.random() * maxRandomX);
			let y = top + Math.floor(Math.random() * maxRandomY);
			image.set({
				left: x,
				top: y,
			});
			imageData.forEach((img) => {
				if (image.intersectsWithObject(img)) {
					overlapping = true;
				}
			});
			if (!overlapping) {
				imageData.push(image);
			}
			protection--;
			if (protection === 0) {
				return;
			}
		}
		const group = new fabric.Group(imageData, {
			uid: uuidv4(),
		});
		group.addWithUpdate(
			new fabric.Rect({
				left: left,
				top: top,
				width: maxGroupWidth,
				height: maxGroupHeight,
				strokeWidth: 2,
				stroke: withBorder ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0)',
				fill: 'rgba(0,0,0,0)',
			})
		);
		canvas.add(group);
		if (activeOnLoad) canvas.setActiveObject(group);
	};
};

/**
 * Function that add cut grid, usually use in cutout canvas
 * @param   {Object} canvas       target canvas
 * @param   {Array} grid          grid array
 * @param   {number} borderOffset   border offset from grid and canva edge
 */
export const addCutGrid = (canvas, grid = [2, 2], borderOffset = 12) => {
	const width = canvas.width / canvas.getZoom();
	const height = canvas.height / canvas.getZoom();
	const rowSize = (height - 2 * borderOffset) / grid[0];
	const colSize = (width - 2 * borderOffset) / grid[1];
	const lines = [];
	const lineOption = {
		stroke: 'rgba(0,0,0,1)',
		strokeWidth: 1,
		selectable: false,
		strokeDashArray: [3, 3],
	};
	for (let r = 0; r < grid[0] + 1; r++) {
		lines.push(
			new fabric.Line(
				[0, rowSize * r, width - 2 * borderOffset, rowSize * r],
				lineOption
			)
		);
	}
	for (let c = 0; c < grid[1] + 1; c++) {
		lines.push(
			new fabric.Line(
				[colSize * c, 0, colSize * c, height - 2 * borderOffset],
				lineOption
			)
		);
	}
	const gridGroup = new fabric.Group(lines, {
		uid: uuidv4(),
		as: 'cut_grid',
		left: borderOffset,
		top: borderOffset,
		selectable: false,
		hoverCursor: 'default',
	});
	canvas.add(gridGroup);
	canvas.sendToBack(gridGroup);
};

/**
 * Function that listens object added event on given canvas
 * @param   {Object} canvas       target canvas
 */
export const addObjectAddedEventListener = (canvas) => {
	canvas.on('object:added', (e) => {
		switch (e.target.type) {
			case 'text':
			case 'i-text':
				e.target.setControlsVisibility({
					mt: false,
					mb: false,
					mr: false,
					ml: false,
					mtr: true,
				});
				break;
			case 'ellipse':
			case 'rect':
			case 'triangle':
			case 'path':
				e.target.setControlsVisibility({
					mt: true,
					mb: true,
					mr: true,
					ml: true,
					mtr: true,
				});
				break;
			case 'group':
				e.target.setControlsVisibility({
					mt: false,
					mb: false,
					mr: false,
					ml: false,
					mtr: true,
				});
				break;
			default:
		}
	});
};

/**
 * Function that listens object scaling event on given canvas
 * @param   {Object} canvas       target canvas
 */
export const addObjectScalingEventListener = (canvas, setFunctions = {}) => {
	const { setFontSize } = setFunctions;
	canvas.on('object:scaling', (e) => {
		switch (e.target.type) {
			case 'text':
			case 'i-text':
				const fontSize = Math.round(
					e.target.fontSize * e.target.scaleX
				);
				canvas.getObjects().forEach((obj) => {
					if (obj.uid === e.target.uid) {
						obj.set({ fontSize: fontSize });
					}
				});
				e.target.set({
					scaleX: 1,
					scaleY: 1,
				});
				setFontSize && setFontSize(fontSize);
				break;
			case 'ellipse':
				const rx = e.target.rx * e.target.scaleX;
				const ry = e.target.ry * e.target.scaleY;
				canvas.getObjects().forEach((obj) => {
					if (obj.uid === e.target.uid) {
						obj.set({ rx: rx, ry: ry });
					}
				});
				e.target.set({
					scaleX: 1,
					scaleY: 1,
				});
				break;
			case 'rect':
				const rect_w = e.target.width * e.target.scaleX;
				const rect_h = e.target.height * e.target.scaleY;
				canvas.getObjects().forEach((obj) => {
					if (obj.uid === e.target.uid) {
						obj.set({ width: rect_w, height: rect_h });
					}
				});
				e.target.set({
					scaleX: 1,
					scaleY: 1,
				});
				break;
			case 'triangle':
				const tri_w = e.target.width * e.target.scaleX;
				const tri_h = e.target.height * e.target.scaleY;
				canvas.getObjects().forEach((obj) => {
					if (obj.uid === e.target.uid) {
						obj.set({ width: tri_w, height: tri_h });
					}
				});
				e.target.set({
					scaleX: 1,
					scaleY: 1,
				});
				break;
			default:
				canvas.getObjects().forEach((obj) => {
					if (obj.uid === e.target.uid) {
						obj.set({
							scaleX: e.target.scaleX,
							scaleY: e.target.scaleY,
						});
					}
				});
		}
	});
};

/**
 * Function that listens object moving event on given canvas
 * @param   {Object} canvas       target canvas
 */
export const addObjectMovingEventListener = (canvas) => {
	canvas.on('object:moving', (e) => {
		const { x: canvasCenterX, y: canvasCenterY } = canvas.getVpCenter();
		const { x: objectCenterX, y: objectCenterY } =
			e.target.getCenterPoint();
		if (Math.abs(objectCenterX - canvasCenterX) < 5) {
			e.target.setPositionByOrigin(
				{
					x: canvasCenterX,
					y: e.target.getCenterPoint().y,
				},
				'center',
				'center'
			);
		}
		if (Math.abs(objectCenterY - canvasCenterY) < 5) {
			e.target.setPositionByOrigin(
				{
					x: e.target.getCenterPoint().x,
					y: canvasCenterY,
				},
				'center',
				'center'
			);
		}
		const { x: target_x, y: target_y } = e.target.getPointByOrigin(
			'left',
			'top'
		);
		const target_w = e.target.getScaledWidth();
		const target_h = e.target.getScaledHeight();
		canvas.getObjects().forEach((ref) => {
			if (ref !== e.target) {
				const { x: ref_x, y: ref_y } = ref.getPointByOrigin(
					'left',
					'top'
				);
				const ref_w = ref.getScaledWidth();
				const ref_h = ref.getScaledHeight();
				//left-left or right-right
				if (Math.abs(target_x - ref_x) < 5) {
					e.target.setPositionByOrigin(
						{
							x: ref_x,
							y: e.target.getPointByOrigin('left', 'top').y,
						},
						'left',
						'top'
					);
				} else if (
					Math.abs(target_x + target_w - (ref_x + ref_w)) < 5
				) {
					e.target.setPositionByOrigin(
						{
							x: ref_x + ref_w,
							y: e.target.getPointByOrigin('left', 'top').y,
						},
						'right',
						'top'
					);
				}
				//top-top or bottom-bottom
				if (Math.abs(target_y - ref_y) < 5) {
					e.target.setPositionByOrigin(
						{
							x: e.target.getPointByOrigin('left', 'top').x,
							y: ref_y,
						},
						'left',
						'top'
					);
				} else if (
					Math.abs(target_y + target_h - (ref_y + ref_h)) < 5
				) {
					e.target.setPositionByOrigin(
						{
							x: e.target.getPointByOrigin('left', 'top').x,
							y: ref_y + ref_h,
						},
						'left',
						'bottom'
					);
				}
				//left-right
				if (Math.abs(target_x - (ref_x + ref_w)) < 5) {
					e.target.setPositionByOrigin(
						{
							x: ref_x + ref_w,
							y: e.target.getPointByOrigin('left', 'top').y,
						},
						'left',
						'top'
					);
				}
				//top-bottom
				if (Math.abs(target_y - (ref_y + ref_h)) < 5) {
					e.target.setPositionByOrigin(
						{
							x: e.target.getPointByOrigin('left', 'top').x,
							y: ref_y + ref_h,
						},
						'left',
						'top'
					);
				}
				//right-left
				if (Math.abs(target_x + target_w - ref_x) < 5) {
					e.target.setPositionByOrigin(
						{
							x: ref_x,
							y: e.target.getPointByOrigin('left', 'top').y,
						},
						'right',
						'top'
					);
				}
				//bottom-top
				if (Math.abs(target_y + target_h - ref_y) < 5) {
					e.target.setPositionByOrigin(
						{
							x: e.target.getPointByOrigin('left', 'top').x,
							y: ref_y,
						},
						'left',
						'bottom'
					);
				}
			}
		});
	});
};

/**
 * Function that listens object rotating event on given canvas
 * @param   {Object} canvas       target canvas
 */
export const addObjectRotatingEventListener = (canvas) => {
	canvas.on('object:rotating', (e) => {
		if (e.target.angle < 5) {
			e.target.set({ angle: 0 });
		}
	});
};

/**
 * Function that listens text event on given canvas
 * @param   {Object} canvas       target canvas
 */
export const addTextEventListener = (canvas) => {
	canvas.on('text:changed', (e) => {
		canvas.getObjects().forEach((obj) => {
			if (obj.uid === e.target.uid) {
				obj.set({ text: e.target.text });
			}
		});
	});
	canvas.on('text:editing:exited', (e) => {
		if (e.target.text === '') {
			canvas.getObjects().forEach((obj) => {
				if (obj.uid === e.target.uid) {
					obj.set({ text: 'Text' });
				}
			});
		}
	});
};

/**
 * Function that listens selection event on given canvas
 * @param   {Object} canvas       target canvas
 */
export const addSelectionEventListener = (canvas, setFunctions = {}) => {
	const { setFontFamily, setFontSize, setFill, setStroke } = setFunctions;
	const onSelected = (e) => {
		const object = e.selected[0];
		object.fontFamily && setFontFamily && setFontFamily(object.fontFamily);
		object.fontSize && setFontSize && setFontSize(object.fontSize);
		object.fill && setFill && setFill(object.fill);
		object.stroke && setStroke && setStroke(object.stroke);
	};
	canvas.on({
		'selection:created': onSelected,
		'selection:updated': onSelected,
	});
};

/**
 * Function that listens if guidelines should be drawn
 * @param   {Object} canvas       target canvas
 */
export const addGuidelineEventListener = (canvas) => {
	canvas.on('before:render', () => {
		const ctx = canvas.contextTop;
		if (ctx) canvas.clearContext(ctx);
	});
	canvas.on('after:render', () => {
		const activeObjects = canvas.getActiveObjects();
		const ctx = canvas.contextTop;
		if (ctx && activeObjects.length > 0) {
			let fabricObject = activeObjects[0];
			canvas.getObjects().forEach((obj) => {
				if (obj !== fabricObject && obj.uid === fabricObject.uid) {
					obj._renderControls(ctx, {
						borderColor: colors.macaron.yellow,
						hasControls: false,
					});
				}
			});
			ctx.save();
			ctx.globalAlpha = 0.3;
			ctx.strokeStyle = colors.macaron.red;
			ctx.lineWidth = 2;
			ctx.beginPath();
			ctx.setLineDash([10, 10]);
			let hasVerticalGuideLine = false;
			let hasHorizontalGuideLine = false;
			//centered guide lines
			const width = canvas.width;
			const height = canvas.height;
			const { x: canvasCenterX, y: canvasCenterY } = canvas.getVpCenter();
			const { x: objectCenterX, y: objectCenterY } =
				fabricObject.getCenterPoint();
			// (vertical)
			if (Math.abs(canvasCenterX - objectCenterX) < 5) {
				ctx.moveTo(width / 2, 0);
				ctx.lineTo(width / 2, height);
				hasVerticalGuideLine = true;
			}
			// (horizontal)
			if (Math.abs(canvasCenterY - objectCenterY) < 5) {
				ctx.moveTo(0, height / 2);
				ctx.lineTo(width, height / 2);
				hasHorizontalGuideLine = true;
			}
			//object alignment guide line
			const scale = canvas.getZoom();
			const { x: target_x, y: target_y } = fabricObject.getPointByOrigin(
				'left',
				'top'
			);
			const target_w = fabricObject.getScaledWidth();
			const target_h = fabricObject.getScaledHeight();
			canvas.getObjects().forEach((ref) => {
				if (ref !== fabricObject) {
					const { x: ref_x, y: ref_y } = ref.getPointByOrigin(
						'left',
						'top'
					);
					const ref_w = ref.getScaledWidth();
					const ref_h = ref.getScaledHeight();
					//left-left or right-right or left-right or right-left (vertical)
					if (!hasVerticalGuideLine) {
						if (Math.abs(target_x - ref_x) < 0.01) {
							ctx.moveTo(ref_x * scale, 0);
							ctx.lineTo(ref_x * scale, height);
							hasVerticalGuideLine = true;
						} else if (
							Math.abs(target_x + target_w - (ref_x + ref_w)) <
							0.01
						) {
							ctx.moveTo((ref_x + ref_w) * scale, 0);
							ctx.lineTo((ref_x + ref_w) * scale, height);
							hasVerticalGuideLine = true;
						} else if (
							Math.abs(target_x - (ref_x + ref_w)) < 0.01
						) {
							ctx.moveTo((ref_x + ref_w) * scale, 0);
							ctx.lineTo((ref_x + ref_w) * scale, height);
							hasVerticalGuideLine = true;
						} else if (
							Math.abs(target_x + target_w - ref_x) < 0.01
						) {
							ctx.moveTo(ref_x * scale, 0);
							ctx.lineTo(ref_x * scale, height);
							hasVerticalGuideLine = true;
						}
					}
					//top-top or bottom-bottom or top-bottom or bottom-top (horizontal)
					if (!hasHorizontalGuideLine) {
						if (Math.abs(target_y - ref_y) < 0.01) {
							ctx.moveTo(0, ref_y * scale);
							ctx.lineTo(width, ref_y * scale);
							hasHorizontalGuideLine = true;
						} else if (
							Math.abs(target_y + target_h - (ref_y + ref_h)) <
							0.01
						) {
							ctx.moveTo(0, (ref_y + ref_h) * scale);
							ctx.lineTo(width, (ref_y + ref_h) * scale);
							hasHorizontalGuideLine = true;
						} else if (
							Math.abs(target_y - (ref_y + ref_h)) < 0.01
						) {
							ctx.moveTo(0, (ref_y + ref_h) * scale);
							ctx.lineTo(width, (ref_y + ref_h) * scale);
							hasHorizontalGuideLine = true;
						} else if (
							Math.abs(target_y + target_h - ref_y) < 0.01
						) {
							ctx.moveTo(0, ref_y * scale);
							ctx.lineTo(width, ref_y * scale);
							hasHorizontalGuideLine = true;
						}
					}
				}
			});
			ctx.stroke();
			ctx.closePath();
			ctx.restore();
		}
	});
};

/**
 * Function that removes all event listeners
 * @param   {Object} canvas       target canvas
 */
export const removeAllEventListeners = (canvas) => {
	canvas.off('object:scaling');
	canvas.off('object:moving');
	canvas.off('text:changed');
	canvas.off('text:editing:exited');
	canvas.off('selection:created');
	canvas.off('selection:updated');
	canvas.off('before:render');
	canvas.off('after:render');
};
