import React, { useContext } from 'react';
import { Flex, VStack, Divider } from '@chakra-ui/react';
import {
	Row,
	FontSizeCol,
	FontFamilyCol,
	ColorPickerCol,
	HeadingCol,
} from '../Features';
import CountContext from './CountContext';
import { REGULAR_FONT_OPTIONS } from '../../fonts/fontOptions';

const CountEdit = () => {
	const {
		canvas,
		fontFamily,
		setFontFamily,
		fontSize,
		setFontSize,
		fill,
		setFill,
		stroke,
		setStroke,
	} = useContext(CountContext);

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<HeadingCol text="Font" />
					<FontFamilyCol
						canvas={canvas}
						fontFamily={fontFamily}
						setFontFamily={setFontFamily}
						options={REGULAR_FONT_OPTIONS}
					/>
					<FontSizeCol
						canvas={canvas}
						fontSize={fontSize}
						setFontSize={setFontSize}
					/>
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Color" />
					<ColorPickerCol
						canvas={canvas}
						type="fill"
						value={fill}
						setValue={setFill}
					/>
					<ColorPickerCol
						canvas={canvas}
						type="stroke"
						value={stroke}
						setValue={setStroke}
					/>
				</Row>
			</VStack>
		</Flex>
	);
};

export default CountEdit;
