import React, { useState, useEffect, useRef } from 'react';
import { Flex, Center, Text } from '@chakra-ui/react';
import SubNav from '../SubNav';
import CountAdd from './CountAdd';
import CountEdit from './CountEdit';
import CountSetting from './CountSetting';
import { DownloadForm, FloatEditor } from '../Features';

import {
	initializeCanvas,
	addObjectAddedEventListener,
	addObjectScalingEventListener,
	addObjectMovingEventListener,
	addObjectRotatingEventListener,
	addTextEventListener,
	addSelectionEventListener,
	addGuidelineEventListener,
} from '../../fabricCanvas';

import CountContext from './CountContext';
import { example_1 } from './CountExample';

const Count = () => {
	const [zoom, setZoom] = useState(1);
	const [canvas, setCanvas] = useState(null);
	const [fontFamily, setFontFamily] = useState('Times New Roman');
	const [fontSize, setFontSize] = useState(50);
	const [fill, setFill] = useState('rgba(0,0,0,1)');
	const [stroke, setStroke] = useState('rgba(0,0,0,0)');
	const [preciseSelection, setPreciseSelection] = useState(true);
	const [centeredScaling, setCenteredScaling] = useState(false);
	const rightRef = useRef(null);

	useEffect(() => {
		//get and set canvas
		const canvas = initializeCanvas('countCanvas');
		addObjectAddedEventListener(canvas);
		addObjectScalingEventListener(canvas, {
			setFontSize,
		});
		addObjectMovingEventListener(canvas);
		addObjectRotatingEventListener(canvas);
		addTextEventListener(canvas);
		addSelectionEventListener(canvas, {
			setFontFamily,
			setFontSize,
			setFill,
			setStroke,
		});
		addGuidelineEventListener(canvas);
		example_1(canvas);
		setCanvas(canvas);
		//set zoom
		const { clientWidth, clientHeight } = rightRef.current;
		const zoom = (
			Math.min(clientWidth / 612, clientHeight / 792) * 0.92
		).toFixed(2);
		canvas.setZoom(zoom);
		canvas.setDimensions({
			width: 612 * zoom,
			height: 792 * zoom,
		});
		setZoom(zoom);
		//listen to keyboard
		const handleKeyDown = (event) => {
			if (event.keyCode === 46) {
				canvas.getActiveObjects().forEach((obj) => canvas.remove(obj));
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			canvas.dispose();
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (canvas) {
			const handleResize = () => {
				const { clientWidth, clientHeight } = rightRef.current;
				const zoom = (
					Math.min(clientWidth / 612, clientHeight / 792) * 0.92
				).toFixed(2);
				canvas.setZoom(zoom);
				canvas.setDimensions({
					width: 612 * zoom,
					height: 792 * zoom,
				});
				setZoom(zoom);
			};
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [canvas]);

	return (
		<CountContext.Provider
			value={{
				canvas,
				fontFamily,
				setFontFamily,
				fontSize,
				setFontSize,
				fill,
				setFill,
				stroke,
				setStroke,
				preciseSelection,
				setPreciseSelection,
				centeredScaling,
				setCenteredScaling,
			}}
		>
			<Flex flex="1" bg="macaron.white" overflow="hidden">
				<SubNav
					add={<CountAdd />}
					edit={<CountEdit />}
					setting={<CountSetting />}
					download={<DownloadForm canvas={canvas} />}
				/>

				<Center flex="1" minW="0" position="relative" ref={rightRef}>
					<canvas
						id={'countCanvas'}
						width={612}
						height={792}
						style={{
							boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
						}}
					/>
					<Text position="absolute" right="5px" bottom="5px">
						{`${(zoom * 100).toFixed(0)}%`}
					</Text>
					{canvas && <FloatEditor canvas={canvas} />}
				</Center>
			</Flex>
		</CountContext.Provider>
	);
};

export default Count;
