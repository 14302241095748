import React, { useRef, useState, useEffect } from 'react';

const FloatPane = ({
	children,
	initialLeft,
	initialTop,
	width,
	barThickness,
	barColor,
	...props
}) => {
	const [left, setLeft] = useState(initialLeft); //in %
	const [top, setTop] = useState(initialTop); //in %
	const offsetX = useRef(0); //in px
	const offsetY = useRef(0); //in px
	const divRef = useRef(null);
	const barRef = useRef(null);
	const [barHover, setBarHover] = useState(false);

	//pointer event
	const onPointerDown = (e) => {
		barRef.current.style.opacity = 1;
		offsetX.current =
			e.clientX - barRef.current.getBoundingClientRect().left;
		offsetY.current =
			e.clientY - barRef.current.getBoundingClientRect().top;
		document.addEventListener('pointerup', onPointerUp);
		document.addEventListener('pointermove', onPointerMove);
	};

	const onPointerUp = (e) => {
		barRef.current.style.opacity = 0.5;
		document.removeEventListener('pointerup', onPointerUp);
		document.removeEventListener('pointermove', onPointerMove);
	};

	const onPointerMove = (e) => {
		let parentBoundingClientRect =
			divRef.current.parentNode.getBoundingClientRect();
		let left_px =
			e.clientX - parentBoundingClientRect.left - offsetX.current;
		let top_px = e.clientY - parentBoundingClientRect.top - offsetY.current;
		if (left_px < 5) {
			left_px = 0;
		}
		if (top_px < 5) {
			top_px = 0;
		}
		if (left_px > parentBoundingClientRect.width - width - 5) {
			left_px = parentBoundingClientRect.width;
		}
		if (top_px > parentBoundingClientRect.height - barThickness - 5) {
			top_px = parentBoundingClientRect.height;
		}
		let left_per = (left_px / parentBoundingClientRect.width) * 100;
		let top_per = (top_px / parentBoundingClientRect.height) * 100;
		setLeft(left_per);
		setTop(top_per);
	};

	useEffect(() => {
		const handleResize = () => {
			if (
				divRef.current.offsetLeft >
				divRef.current.parentNode.clientWidth - width
			) {
				setLeft(100);
			}
			if (
				divRef.current.offsetTop >
				divRef.current.parentNode.clientHeight - barThickness
			) {
				setTop(100);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [width, barThickness]);

	const onBarMouseEnter = () => {
		setBarHover(true);
	};

	const onBarMouseLeave = () => {
		setBarHover(false);
	};

	return (
		<div
			{...props}
			ref={divRef}
			style={{
				position: 'absolute',
				left: `calc(${left}% - ${left === 100 ? width : 0}px)`,
				top: `calc(${top}% - ${top === 100 ? barThickness : 0}px)`,
				width: `${width}px`,
				display: 'flex',
				flexDirection: 'column',
				touchAction: 'none',
				boxShadow:
					'0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
			}}
		>
			<div
				style={{
					height: barThickness,
					backgroundColor: barColor,
					opacity: barHover ? 0.75 : 0.5,
					cursor: 'move',
				}}
				ref={barRef}
				onPointerDown={onPointerDown}
				onMouseEnter={onBarMouseEnter}
				onMouseLeave={onBarMouseLeave}
			/>
			{children}
		</div>
	);
};

// Set default props
FloatPane.defaultProps = {
	initialLeft: 0,
	initialTop: 0,
	width: 30,
	barThickness: 4,
	barColor: 'black',
};

export default FloatPane;
