import {
	addIText,
	addRect,
	addText,
	addMultipleImageCopiesOrdered,
} from '../../fabricCanvas';

export const example_1 = (canvas) => {
	addRect(canvas, {
		left: 10,
		top: 10,
		width: 589,
		height: 769,
	});
	addIText(canvas, {
		fontFamily: 'Times New Roman',
		fontSize: 24,
		text: 'Name:________',
		left: 60,
		top: 40,
	});
	addIText(canvas, {
		fontFamily: 'Times New Roman',
		fontSize: 24,
		text: 'Class:________',
		left: 380,
		top: 40,
	});
	addIText(canvas, {
		fontFamily: 'Times New Roman',
		fontSize: 50,
		text: 'Count and Match',
		left: 131.916015625,
		top: 92.8,
	});

	addMultipleImageCopiesOrdered(canvas, 'assets/images/sport/baseball.svg', {
		count: 4,
		width: 40,
		left: 45,
		top: 173,
		activeOnLoad: false,
	});
	addMultipleImageCopiesOrdered(canvas, 'assets/images/sport/soccer.svg', {
		count: 1,
		width: 40,
		left: 45,
		top: 244,
		activeOnLoad: false,
	});
	addMultipleImageCopiesOrdered(canvas, 'assets/images/sport/badminton.svg', {
		count: 9,
		width: 40,
		left: 45,
		top: 318,
		activeOnLoad: false,
	});
	addMultipleImageCopiesOrdered(
		canvas,
		'assets/images/sport/basketball.svg',
		{
			count: 2,
			width: 40,
			left: 45,
			top: 445,
			activeOnLoad: false,
		}
	);
	addMultipleImageCopiesOrdered(
		canvas,
		'assets/images/sport/colored_volleyball.svg',
		{
			count: 6,
			width: 40,
			left: 45,
			top: 522,
			activeOnLoad: false,
		}
	);
	addMultipleImageCopiesOrdered(canvas, 'assets/images/sport/tennis.svg', {
		count: 8,
		width: 40,
		left: 45,
		top: 635,
		activeOnLoad: false,
	});
	addText(canvas, {
		text: '1',
		fontFamily: 'Fredoka One',
		fontSize: 50,
		left: 505,
		top: 172,
	});
	addText(canvas, {
		text: '8',
		fontFamily: 'Fredoka One',
		fontSize: 50,
		left: 505,
		top: 269,
	});
	addText(canvas, {
		text: '2',
		fontFamily: 'Fredoka One',
		fontSize: 50,
		left: 505,
		top: 367,
	});
	addText(canvas, {
		text: '6',
		fontFamily: 'Fredoka One',
		fontSize: 50,
		left: 505,
		top: 452,
	});
	addText(canvas, {
		text: '9',
		fontFamily: 'Fredoka One',
		fontSize: 50,
		left: 505,
		top: 559,
	});
	addText(canvas, {
		text: '4',
		fontFamily: 'Fredoka One',
		fontSize: 50,
		left: 505,
		top: 659,
	});
	addIText(canvas, {
		fontFamily: 'Times New Roman',
		fontSize: 16,
		text: '@earlyeducationlab.com',
		left: 435,
		top: 757,
	});
	canvas.discardActiveObject();
};
