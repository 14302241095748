export const REGULAR_FONT_OPTIONS = [
	'Architects Daughter',
	'Arial',
	'Fredoka One',
	'Roboto',
	'Times New Roman',
];

export const TRACE_FONT_OPTIONS = ['KG Primary Dots', 'KG Primary Dots Lined'];

export const CUTOUT_FONT_OPTIONS = ['Fredoka One'];
