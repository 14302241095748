import React, { useContext } from 'react';
import { Flex, VStack } from '@chakra-ui/react';
import { Row, PreciseSelectionCol, CenteredScalingCol } from '../Features';
import TraceContext from './TraceContext';

const TraceSetting = () => {
	const {
		canvas,
		preciseSelection,
		setPreciseSelection,
		centeredScaling,
		setCenteredScaling,
	} = useContext(TraceContext);

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<PreciseSelectionCol
						canvas={canvas}
						value={preciseSelection}
						setValue={setPreciseSelection}
					/>
					<CenteredScalingCol
						canvas={canvas}
						value={centeredScaling}
						setValue={setCenteredScaling}
					/>
				</Row>
			</VStack>
		</Flex>
	);
};

export default TraceSetting;
