import React, { useContext } from 'react';
import { Flex, VStack, Divider, Button } from '@chakra-ui/react';
import {
	Row,
	FontSizeCol,
	FontFamilyCol,
	ColorPickerCol,
	HeadingCol,
} from '../Features';
import CutoutContext from './CutoutContext';
import { CUTOUT_FONT_OPTIONS } from '../../fonts/fontOptions';

const CutoutEdit = () => {
	const {
		canvas,
		fontFamily,
		setFontFamily,
		fontSize,
		setFontSize,
		fill,
		setFill,
		stroke,
		setStroke,
	} = useContext(CutoutContext);

	const onSyncFont = () => {
		canvas.getObjects().forEach((obj) => {
			if ('fontSize' in obj) {
				obj.set({ fontSize: fontSize });
				canvas.renderAll();
			}
		});
	};

	const onSyncColor = () => {
		canvas.getObjects().forEach((obj) => {
			if ('fill' in obj) {
				obj.set({ fill: fill });
				canvas.renderAll();
			}
			if ('stroke' in obj) {
				obj.set({ stroke: stroke });
				canvas.renderAll();
			}
		});
	};

	return (
		<Flex h="100%" direction="column">
			<VStack flex={1} align="stretch" overflow="auto" spacing={4} p={2}>
				<Row>
					<HeadingCol text="Font" />
					<FontFamilyCol
						canvas={canvas}
						fontFamily={fontFamily}
						setFontFamily={setFontFamily}
						options={CUTOUT_FONT_OPTIONS}
					/>
					<FontSizeCol
						canvas={canvas}
						fontSize={fontSize}
						setFontSize={setFontSize}
					/>
					<Flex flex={`0 0 120px`} alignItems="end" p={2}>
						<Button
							w="100%"
							colorScheme="macaron_blue"
							onClick={onSyncFont}
						>
							Sync
						</Button>
					</Flex>
				</Row>
				<Divider />
				<Row>
					<HeadingCol text="Color" />
					<ColorPickerCol
						canvas={canvas}
						type="fill"
						value={fill}
						setValue={setFill}
					/>
					<ColorPickerCol
						canvas={canvas}
						type="stroke"
						value={stroke}
						setValue={setStroke}
					/>
					<Flex flex={`0 0 120px`} alignItems="end" p={2}>
						<Button
							w="100%"
							colorScheme="macaron_blue"
							onClick={onSyncColor}
						>
							Sync
						</Button>
					</Flex>
				</Row>
			</VStack>
		</Flex>
	);
};

export default CutoutEdit;
