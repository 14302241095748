import React, { createContext } from 'react';
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Icon,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { BsPlus, BsPencil, BsGear, BsDownload } from 'react-icons/bs';
import { colors } from '../index';

const ICON_SIZE = 5;

export const SubNavContext = createContext();

const SubNav = ({
	add = null,
	edit = null,
	setting = null,
	download = null,
}) => {
	const [isExtend] = useMediaQuery('(min-width: 1300px)');
	const [isDisappear] = useMediaQuery('(max-width: 812px)');

	return (
		<SubNavContext.Provider
			value={{
				isExtend,
				isDisappear,
			}}
		>
			<Tabs
				w={isExtend ? '502px' : '262px'}
				flexShrink="0"
				colorScheme="macaron_blue"
				display={isDisappear ? 'none' : 'flex'}
				flexDirection="column"
				borderRight={`2px solid ${colors.macaron.red}`}
			>
				<TabList bg="white" h="50px" flexShrink="0">
					<Tab flex="1">
						<Icon as={BsPlus} w={ICON_SIZE} h={ICON_SIZE} />
					</Tab>
					<Tab flex="1">
						<Icon as={BsPencil} w={ICON_SIZE} h={ICON_SIZE} />
					</Tab>
					<Tab flex="1">
						<Icon as={BsGear} w={ICON_SIZE} h={ICON_SIZE} />
					</Tab>
					<Tab flex="1">
						<Icon as={BsDownload} w={ICON_SIZE} h={ICON_SIZE} />
					</Tab>
				</TabList>
				<TabPanels flex="1" minH="0">
					<TabPanel h="100%" p="0">
						{add}
					</TabPanel>
					<TabPanel h="100%" p="0">
						{edit}
					</TabPanel>
					<TabPanel h="100%" p="0">
						{setting}
					</TabPanel>
					<TabPanel h="100%" p="0">
						{download}
					</TabPanel>
				</TabPanels>
			</Tabs>
		</SubNavContext.Provider>
	);
};

export default SubNav;
