import React from 'react';

export const Unordered = ({ size = 16, color = 'currentColor' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill={color}
			viewBox="0 0 16 16"
		>
			<circle cx="1.9" cy="3.9" r="1.2" />
			<circle cx="5.6" cy="6.9" r="1.2" />
			<circle cx="7.5" cy="1.8" r="1.2" />
			<circle cx="11.6" cy="7" r="1.2" />
			<circle cx="13.9" cy="3" r="1.2" />
			<circle cx="2.6" cy="13.5" r="1.2" />
			<circle cx="7.6" cy="11.1" r="1.2" />
			<circle cx="12.7" cy="14" r="1.2" />
		</svg>
	);
};
