import React, { useRef } from 'react';
import {
	Box,
	Input,
	IconButton,
	AspectRatio,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
} from '@chakra-ui/react';
import {
	addIText,
	addImage,
	addEllipse,
	addRect,
	addTriangle,
	addStar,
} from '../../fabricCanvas';
import {
	TIcon,
	ShapesIcon,
	ImageIcon,
	CircleIcon,
	SquareIcon,
	TriangleIcon,
	StarIcon,
} from '../../icons';

import { IconButtonCol, Row } from './index';

export const AddTextCol = ({ canvas }) => {
	const onClick = () => {
		addIText(canvas, {
			fontFamily: 'Times New Roman',
			fontSize: 70,
		});
	};

	return (
		<Box flex="0 0 120px" p={2}>
			<AspectRatio w="100%" ratio={1}>
				<IconButton
					w="100%"
					h="100%"
					bg="white"
					aria-label="add text"
					icon={<TIcon size={60} />}
					onClick={onClick}
				/>
			</AspectRatio>
		</Box>
	);
};

export const AddShapeCol = ({ canvas }) => {
	return (
		<Box flex="0 0 120px" p={2}>
			<Popover>
				<PopoverTrigger>
					<AspectRatio w="100%" ratio={1}>
						<IconButton
							w="100%"
							h="100%"
							bg="white"
							aria-label="add shape"
							icon={<ShapesIcon size={60} />}
						/>
					</AspectRatio>
				</PopoverTrigger>
				<PopoverContent w="fit-content">
					<PopoverArrow />
					<Box w="256px">
						<Row>
							<IconButtonCol
								label="solid circle"
								icon={<CircleIcon />}
								onClick={() => addEllipse(canvas)}
							/>
							<IconButtonCol
								label="solid square"
								icon={<SquareIcon />}
								onClick={() => addRect(canvas)}
							/>
							<IconButtonCol
								label="solid triangle"
								icon={<TriangleIcon />}
								onClick={() => addTriangle(canvas)}
							/>
							<IconButtonCol
								label="solid star"
								icon={<StarIcon />}
								onClick={() => addStar(canvas)}
							/>
						</Row>
					</Box>
				</PopoverContent>
			</Popover>
		</Box>
	);
};

export const ImageReaderCol = ({ canvas }) => {
	const inputRef = useRef();

	const onChange = (e) => {
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			addImage(canvas, reader.result);
		};
	};

	return (
		<Box flex="0 0 120px" p={2}>
			<AspectRatio w="100%" ratio={1}>
				<>
					<Input
						ref={inputRef}
						type="file"
						accept="image/*"
						display="none"
						onChange={onChange}
						onClick={() => (inputRef.current.value = null)}
					/>
					<IconButton
						w="100%"
						h="100%"
						bg="white"
						aria-label="image reader"
						icon={<ImageIcon size={60} />}
						onClick={() => inputRef.current.click()}
					/>
				</>
			</AspectRatio>
		</Box>
	);
};
