import React from 'react';
import { Box, Flex, Text, Switch } from '@chakra-ui/react';

export const PreciseSelectionCol = ({ canvas, value, setValue }) => {
	const onChange = () => {
		if (!value) {
			canvas.perPixelTargetFind = true;
		} else {
			canvas.perPixelTargetFind = false;
		}
		setValue(!value);
	};

	return (
		<Box flex="0 0 100%" p={2}>
			<Flex align="center" justify="space-between" h="40px">
				<Text>Precise Selection</Text>
				<Switch isChecked={value} onChange={onChange} />
			</Flex>
		</Box>
	);
};
